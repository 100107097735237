import NavBar from "../../Components/Header";
import Footer from "../../Components/Footer";
import Styles from "./privacy.module.css";
import { useLocation } from "react-router-dom";
import * as React from "react";

export const PrivacyPolicy = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NavBar />
      <section className="py-5">
        <div className="container">
          <h3 className={Styles.title}>Paycurra Privacy Policy</h3>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>1. Overview</h5>
            <p className={Styles.section__details}>
              Paycurra has created this Privacy Statement to clarify how we collect, keep, process, share, and transfer your Personal Data during your
              visits to our platform.
              <br /> <br />
              This Privacy Statement is relevant to your Personal Data only when you're using our platform, The Privacy Statement offers you insights
              into how we handle your privacy and provides you options available when using our platform.
              <br /> <br />
              The Glossary section provides you a list of all terms used in this document, and their definition.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>2. Personal Data Capture and Storage</h5>
            <p className={Styles.section__details}>
              We may capture information about you when you explore our platform, some of which may include the following:
            </p>

            <ul className={Styles.list__group}>
              <li>
                <strong>User registration and customer onboarding information –</strong> When you sign up to use our platform, we will collect the
                required Personal Data to provide and meet the functional requirements for the platform use cases you need. This may include your
                name, mailing address, contact number, email address, identification particulars and other additional personal data to set up an
                Account.
              </li>
              <li>
                <strong> Payments and transactional information–</strong> When you make purchases from merchants, redeem points or pay with a coupon,
                we collect data regarding the transaction. This includes specifics about the transaction itself, the amount spent on items or
                services, details about the seller, and information about any payment methods used to complete the transaction. Alongside this, we
                gather Device Information, Technical Usage Data, and Geolocation Information related to the interaction.
              </li>
              <li>
                <strong> Data from Third-Party Sources –</strong> We gather information from third-party sources like merchants, data providers, and
                credit bureaus, where allowed by law.
              </li>
              <li>
                <strong>Additional Information Collected About Your Site and Service Usage –</strong>
                In addition to the aforementioned data, we might gather information connected to your interaction with our platform. This could
                encompass details obtained when you engage with us, get in touch with our customer support teams, or participate in a survey.
              </li>
            </ul>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>3. Need for your Personal Data</h5>
            <p className={Styles.section__details}>
              Your Personal Data is kept to meet our legal or regulatory responsibilities and for our business purposes. Sometimes, we might retain
              Personal Data for durations longer than mandated by law, as long as it aligns with our legitimate business interests and isn't against
              the law. If your Account is closed, we might take measures to protect Personal Data and other info, yet we maintain the right to keep
              and access the data as needed to adhere to relevant laws. Our use and disclosure of such Personal Data will persist following the
              principles laid out in this Privacy Statement.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>4. Personal Data Processing</h5>
            <p className={Styles.section__details}>The following are reasons why we process your Personal Data:</p>

            <ul className={Styles.list__group}>
              <li>
                Platform operations, including:
                <br />
                <ul className={Styles.sub__list__group}>
                  <li>initiating payments, sending money, or adding value to an account;</li>
                  <li>authenticating your access to your Account, safeguarding its privacy.;</li>
                  <li>keeping you informed about your Account, our our platform in general;</li>
                  <li>establishing a link between your Account and a third-party account or platform, enhancing your experience; and</li>
                  <li>
                    conducting creditworthiness and other financial checks, assessing applications, and cross-checking data for accuracy and
                    verification purposes.
                  </li>
                  <li>maintaining up-to-date Account and financial information for optimal service delivery..</li>
                </ul>
              </li>
              <li>
                <strong>Business operations,</strong> for example, monitoring, analyzing, and improving our platform’s performance and functionality.
                We analyze User behavior and gain insights into the way you use our platform.
              </li>
              <li>
                <strong> Fraud and risk mitigation.</strong> Paycurra uses <a href="#">Personal Data,</a> <a href="#">Device Information,</a>{" "}
                <a href="#">Technical Usage Data,</a> and <a href="#">Geolocation Information</a> from our platform and external platforms providing
                Paycurra Services. This aids us in identifying and preventing fraudulent activities and misuse of the Services.
              </li>
              <li>
                <strong>Marketing and sales</strong> about Paycurra Services and the offerings of unrelated businesses. Additionally, we might process
                your Personal Data to customize marketing content, certain Services, or Site experiences. This is done to align better with your
                interests not just on Paycurra but also on third-party websites.
              </li>

              <li>
                <strong>Personalized user experience:</strong> We may use your Personal Data and other information collected in accordance with this
                Privacy Statement to provide targeted displays, Services or offers to you on third-party websites. We may use cookies and other
                tracking technologies to provide these online services and/or work with other third-parties such as merchants, advertising or
                analytics companies to provide these online services.
              </li>
              <li>
                <strong> Legal and regulatory obligations,</strong> including to comply with all applicable laws and regulations.
              </li>
              <li>
                <strong>Customer service,</strong> for example, to contact you about inquiries you made to our customer service team.
              </li>
            </ul>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>5. Personal Data Distribution and Sharing</h5>
            <p className={Styles.section__details}>
              Your personal data might be shared in a number of ways for the following reasons:
              <br />
              <br />
              <strong>Service providers:</strong> We could share Personal Data with external companies that offer services on our behalf. These
              third-party service providers might, for instance, offer you Services, validate your identity, assist in processing transactions,
              deliver advertisements about our products and Services, or offer customer support.
              <br />
              <br />
              <strong>Financial Institutions:</strong> We might also share Personal Data with financial institutions we've partnered with to
              collaboratively create and provide a product. These financial institutions can solely use the data to promote and offer Paycurra-linked
              products, unless you've granted permission for alternate uses. Additionally, we may share Personal Data for transaction processing,
              providing benefits linked to your eligible cards, and maintaining your financial details up to date.
              <br />
              <br />
              <strong>Transaction Partners:</strong> We may share details about you and your account with the other participants in transactions when
              you use our Services. This includes other Users, merchants, and their service providers involved in processing your transactions.
              <strong>Sharing With Other Third Parties:</strong>
              <br />
              <br />
              We may share your information with external parties for Paycurra's business objectives or as permitted or demanded by the law. This
              includes
              <br />
              <br />
            </p>

            <ol className={Styles.list__group}>
              <li>
                Sharing information if it's necessary to comply with laws, legal processes, or regulations. This might involve interactions with law
                enforcement, regulators, government officials, or other third parties.{" "}
              </li>
              <li>Supporting Purchases: In connection with purchases made using our platform:</li>
            </ol>
            <ul className={Styles.list__group}>
              <li>
                Sharing information to evaluate and manage risk and prevent fraud against us, our Users, and fraud related to our platform. This
                extends to instances involving business partners, strategic collaborations, merchants, and individuals.
              </li>
              <li>Sharing with banking partners as required by card association rules for their list of terminated merchants.</li>
              <li>Sharing with credit reporting and collection agencies.</li>
              <li>Sharing with companies we might merge with or be acquired by.</li>
              <li>Sharing to support our audit, compliance, and corporate governance functions.</li>
            </ul>

            <p className={Styles.section__details}>
              <strong>Given your consent:</strong> We may also share your Personal Data and other details when you provide your agreement. This could
              include instances where you allow an account connection with a third-party account or platform.
              <br />
              <br />
              Furthermore, Paycurra might furnish aggregated statistical information to third parties, which includes businesses and the general
              public. This data will show how, when, and why Users access our platform and use our Services. Importantly, this data will not reveal
              your personal identity or provide insights into your specific use of the platform.
              <br />
              <br />
              Your Personal Data won't be shared with third parties for their marketing purposes unless you've given your consent.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>6. Cookies and Tracking Technologies</h5>
            <p className={Styles.section__details}>
              Whenever you access our platform, use our Services, or browse a third-party website that leverages our online Services, we and our
              associates in business, along with our vendors, might deploy cookies and various tracking technologies (collectively referred to as
              "Cookies"). These serve to identify you as a User and personalize your digital experience, the Services you use, and additional online
              content and advertisements. Furthermore, they help us gauge the efficiency of promotions, conduct analytics, and manage risk while
              preventing potential fraud. This contributes to fostering trust and security across our Sites and Services.
              <br /> <br />
              It's important to note that particular attributes and functionalities of our Services and Sites are exclusively accessible through the
              employment of Cookies. As such, should you opt to disable or decline Cookies, your ability to use the Sites and Services could be
              limited or rendered impossible.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>7. Privacy Choices</h5>
            <p className={Styles.section__details}>
              We offer you options in relation to the privacy practices and communications outlined within this Privacy Statement. Many of these
              choices could be elucidated during your registration for, or utilization of, a Service. Also, you might receive directives and cues
              while navigating through the Services. We strive to provide clear explanations and guidance within the very experiences you encounter
              while using our Services.
            </p>

            <strong className="mb-3">Choices Regarding the Personal Data We Collect:</strong>

            <ul className={Styles.list__group}>
              <li>
                <strong> Personal Data:</strong> While Paycurra may request your Personal Data, you retain the right to decline. By doing so, certain
                Services or potentially all of the Services may become unavailable to you.
              </li>
              <li>
                <strong> Location and Device-Level Information:</strong> The device used to access our platforms might gather information about you,
                including Geolocation Information and User usage data. Paycurra may subsequently gather and use this information. To manage the
                collection and use of such data, you can explore the settings available on your device.
              </li>
            </ul>

            <strong className="mb-3">Choices Regarding Cookies:</strong>

            <ul className={Styles.list__group}>
              <li>
                You may choose to manage your cookie preferences. Your browser or internet device, for instance, might permit you to disable, delete,
                or block specific cookies and other tracking technologies. Remember that while you can enable such choices, it may impact your access
                to core features and functions on our platform.
              </li>
              <li>
                When engaging with a Service or visiting parts of a Site, you might encounter the choice of whether to allow the Service or Site to
                "remember" specific details about you. Cookies and other tracking technologies will only be used in line with your permissions.
              </li>
            </ul>

            <strong className="mb-3">Choices Regarding Your Registration and Account Information:</strong>

            <p className={Styles.section__details}>
              If you maintain an Account, you possess the ability to review and modify Personal Data by logging in and directly updating the
              information. Alternatively, feel free to reach out to us for assistance. Even if you don't currently have an Account, or if questions
              about your Account information or other Personal Data arise, don't hesitate to get in touch.
            </p>

            <strong className="mb-3">Choices Relating to How we communicate With You: </strong>

            <p className={Styles.section__details}>Notices, Alerts, and Updates from Us: </p>

            <ul className={Styles.list__group}>
              <li>
                <strong>Marketing:</strong> Expect to receive marketing content regarding our products, collaborations with financial institutions, as
                well as the offerings of external third parties and the broader Paycurra family. These communications may come through a variety of
                channels, including email, text, pop-ups, push notifications, and messaging apps. Should you prefer not to receive such marketing
                communications, follow the instructions that will be shown to you. Account holders have the added option to adjust notification
                preferences within their Account settings. <br />
                <strong>Informational and Other:</strong> Anticipate receiving important notifications mandated or deemed necessary for Users of our
                platform. Opting out of receiving these particular notifications may be impossible. Nonetheless, you could customize the manner and
                format in which you receive these notifications.
              </li>
            </ul>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>8. Your Rights</h5>
            <p className={Styles.section__details}>
              Subject to the confines of Data Protection Laws, you are endowed with certain rights concerning your personal data. These rights
              encompass the ability to request access to your data, rectification of inaccuracies, and data portability. Should you wish to exercise
              these rights, we encourage you to reach out to us for assistance.
              <br />
              <br />
              Inclusive of these rights, you possess the ability to review decisions driven by automated processes. Moreover, the option to revoke
              consent is at your disposal. It's important to note that withdrawing your consent might impact our capacity to offer services to you. If
              you intend to exercise any of these rights, don't hesitate to contact us. If the desire to access all the personal data Paycurra holds
              about you arises, bear in mind that verifying your identity might be necessary.
              <br />
              <br />
              Should you have an Account with any of our Services, the general rule applies: you can examine and modify Personal Data within your
              Account by directly accessing it. This process involves updating the information as needed. Even if you don't possess an Account or if
              inquiries about Account information or other Personal Data arise, our lines of communication are open for you to reach out.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>9. Personal Data Protection</h5>
            <p className={Styles.section__details}>
              Safeguarding your Personal Data is of paramount importance to us. To this end, we use an array of technical, physical, and
              administrative security measures. These measures are thoughtfully designed to offer reasonable protection against potential risks like
              loss, misuse, unauthorized access, disclosure, and tampering of your Personal Data.
              <br /> <br />
              Here are some of the controls we employ to protect your personal data:
            </p>

            <ul className={Styles.list__group}>
              <li>Firewalls</li>
              <li>Data encryption</li>
              <li>Data centre access controls</li>
              <li>Role based access controls and authorization</li>
            </ul>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>10. Users Below the Age of 16 (Children)</h5>
            <p className={Styles.section__details}>
              Our platform is intended for users above 16 years. Data will not be deliberately gathered by our platform from users who are children.
              <br /> <br />
              We are committed to enabling a digitally safe environment for all users, particularly those who are underaged. As such, where data is
              inadvertently gathered from under aged users, we will make every effort to delete it once we are notified or it is brought to our
              attention.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>11. Contact Us</h5>
            <p className={Styles.section__details}>
              For general inquiries about our Privacy Statement and practices, or questions regarding your Account information and Personal Data,
              please contact us. At www.paycurra.com.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>12. Glossary</h5>
            <p className={Styles.section__details}>
              <strong>Account</strong> means a Paycurra user account.
              <br />
              <br />
              <strong> Device Information:</strong> Device Information encompasses data that can be automatically gathered from any device utilized to
              access the Sites or Services. This could involve details like your device type, network connections, device name, IP address, web
              browser information, internet connection specifics, Geolocation Information, data on downloaded apps, and even biometric data such as
              Touch ID/Fingerprint for identity verification.
              <br />
              <br />
              <strong> Geolocation Information:</strong> Geolocation Information pertains to data that accurately identifies your location through
              methods such as GPS, Wi-Fi, or cell site triangulation. Certain Services may ask for your permission to share your current location.
              Keep in mind that specific products or online Services might require this information for proper functionality. If you decline to
              provide geolocation information, the functionality of our Sites or Services might be compromised.
              <br />
              <br />
              <strong> Paycurra:</strong> The term "Paycurra'' refers to the Paycurra website or the Paycurra app. Throughout this Privacy Statement,
              we might refer to Paycurra as "we," "us," or "our," depending on the context.
              <br />
              <br />
              <strong>Personal Data:</strong> Personal Data refers to personally identifiable information linked to an identified or identifiable
              individual. This includes elements like name, postal addresses (including billing and shipping), telephone numbers, email addresses,
              payment card numbers, financial account information, account numbers, date of birth, and government-issued credentials (e.g., driver’s
              license number, national ID, passport, Social Security number, and Taxpayer ID). Personal Data doesn't encompass information that cannot
              be used to identify a specific User.
              <br />
              <br />
              <strong> Process:</strong> Process encompasses any manner in which we handle Personal Data or sets of Personal Data, whether automated
              or not. This involves collection, recording, organization, storage, adaptation, retrieval, consultation, transmission, dissemination,
              alignment, combination, restriction, erasure, or destruction of Personal Data.
              <br />
              <br />
              <strong>Services:</strong> The term "Services" encompasses products, services, content, features, technologies, functions, and all
              connected websites, applications, and services offered by Paycurra.
              <br />
              <br />
              <strong>Sites:</strong> Sites refer to websites, mobile apps, official social media platforms, or other online properties through which
              Paycurra provides the Services, and which have published or linked to this Privacy Statement.
              <br />
              <br />
              <strong>Technical Usage Data:</strong> Technical Usage Data includes information gathered from your devices such as phones, computers,
              or other devices used to access the Sites or Services. It provides insights into your usage patterns, like search queries, viewed
              content, how you interact with our Services, your IP address, page loading statistics, pre-visited websites, and other browsing details
              collected through Cookies.
              <br />
              <br />
              <strong>User:</strong> User pertains to an individual who uses the Services or accesses the Sites.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <p className={Styles.section__details}>
              Paycurra employs ReCaptcha on its Sites and Services as an anti-spam and anti-abuse measure. Your utilization of ReCaptcha is governed
              by the Google <a href="#">Privacy Policy</a> and <a href="#">Terms of Use.</a> Please be aware that ReCaptcha is exclusively employed to
              prevent spam and abuse.
            </p>
          </section>
        </div>
      </section>
      <Footer />
    </>
  );
};
