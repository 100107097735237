import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import paycurra from "../../Assets/images/paycurra-logo.png";
import bookademo from "../../Assets/images/bookDemo.svg";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { IconButton } from "@mui/material";
import SuccessImg from "../../Assets/images/checked-loggedin-img.svg";

const validationSchema = Yup.object().shape({
  contactName: Yup.string().required("Name is required"),
  companySize: Yup.string().required("Select company size"),
  role: Yup.string().required("Role is required"),
  comment: Yup.string().required("Enter your message"),
  companyEmail: Yup.string().email("Provide a valid email").required("Email is required"),
  termsOfUse: Yup.boolean().oneOf([true]),
  privacyPolicy: Yup.boolean().oneOf([true]),
});

interface IProps {
  handleClose?: () => void;
}

const BookADemoPage: React.FC<IProps> = ({ handleClose }) => {
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const tenant = "495DBCCC-21F8-425C-PAYCURRA-FFF51DA887F5";
  const SUBSCRIPTION_KEY = "512dc89e9cef4764ad4c9d50d7a4e417";

  const initialValues: any = {
    companyEmail: "",
    contactName: "",
    companySize: "",
    comment: "",
    role: "",
    termsOfUse: false,
    privacyPolicy: false,
  };

  const handleSubmit = async (data: any) => {
    // console.log(data);
    const config = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY,
        "X-PYC-UserName": "Cognitive",
        "X-PYC-UserURN": "Cognitivelabs",
      },
    };
    try {
      const response = await axios.post(`https://clbs-int-euw-pyc-apim.azure-api.net/subscriptions/${tenant}/v1/EnterprisePlanRequest`, data, config);
      console.log("Response:", response);
      if (response.status === 201 || response.status === 200) {
        setIsSuccess(true);

        setTimeout(() => {
          // handleClose();
        }, 2000);
      }
      // Handle successful response here
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };

  return (
    <section className="container">
      <div className={styles.bookADemo}>
        <div className={styles.header}>
          <Link className="navbar-brand" to="/">
            <img src={paycurra} alt={`Paycurra`} width={145} height={35} />
          </Link>
          <div className={styles.troubleLink}>
            <span>Having trouble?</span>
            <span>Get Help</span>
          </div>
        </div>

        {/* <IconButton onClick={handleClose} className={styles.cancelBtn}>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
              d="M0.758057 11.2431L6.00106 6.00008L11.2441 11.2431M11.2441 0.75708L6.00006 6.00008L0.758057 0.75708"
              stroke="#847F7F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton> */}
        {!isSuccess ? (
          <div className={`${styles.content} row`}>
            <div className="col-lg-6">
              <div className={styles.bookADemoFormContainer}>
                <h6>Book a free demo with our experts</h6>
                <p>Find out how our platform can help you build loyal customers that are connected and engaged for accelerated business growth.</p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { resetForm }) => {
                    handleSubmit(values);
                  }}
                >
                  {({
                    touched,
                    errors,
                    handleBlur,
                    handleSubmit,
                    handleChange,
                    isValid,
                    isSubmitting,
                    values: { contactName, companyEmail, companySize, role, comment, termsOfUse, privacyPolicy },
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <label className={styles.inputLabel}>Full Name</label>
                        <input
                          name="contactName"
                          value={contactName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter full name"
                          className={styles.inputField}
                        />
                        <div className={styles.inputError}> {errors.contactName as string} </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <label className={styles.inputLabel}>Work Email</label>
                          <input
                            name="companyEmail"
                            value={companyEmail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter your work email"
                            className={styles.inputField}
                          />
                          <div className={styles.inputError}> {errors.companyEmail as string} </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className={styles.inputLabel}>Role</label>
                          <input
                            name="role"
                            value={role}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter role"
                            className={styles.inputField}
                          />
                          {errors.role && <div className={styles.inputError}> {errors.role as string} </div>}
                        </div>
                      </div>
                      <div className={"row"}>
                        <div className="col-md-6 mb-4">
                          <label className={styles.inputLabel}>Company Name</label>
                          <input name="company-name" placeholder="Enter your company name" className={styles.inputField} />
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className={styles.inputLabel}>Company Size</label>
                          <select name="companySize" value={companySize} onBlur={handleBlur} onChange={handleChange} className={styles.inputField}>
                            <option disabled selected>
                              Select
                            </option>
                            <option value="1-10">1 - 10</option>
                            <option value="11-50">11 - 50</option>
                            <option value="51-100">51 - 100</option>
                            <option value="101-250">101 - 250</option>
                            <option value="251-1000">251 - 1000</option>
                            <option value="1001-10,000">1,001 - 10,000</option>
                            <option value=">10,000"> &gt; 10000</option>
                          </select>
                          <div className={styles.inputError}> {errors.companySize as string} </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label className={styles.inputLabel}>Message</label>
                        <textarea
                          name="comment"
                          value={comment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter message"
                          className={styles.inputField}
                        ></textarea>

                        <div className={styles.inputError}> {errors.comment as string} </div>
                      </div>

                      <div className={styles.termsAndPrivacyPolicy}>
                        <div className={styles.termsOfService}>
                          <input type="checkbox" className={styles.checkbox} value={termsOfUse} onChange={handleChange} name="termsOfUse" />
                          <label>
                            I confirm that I agree with Paycurra's <Link to="/privacy-policy"> Privacy policy</Link> &amp;{" "}
                            <Link to="/terms-and-conditions"> terms of use</Link>
                          </label>
                        </div>
                        <div className={styles.privacyPolicy}>
                          <input type="checkbox" className={styles.checkbox} value={privacyPolicy} onChange={handleChange} name="privacyPolicy" />
                          <label>
                            I would like to receive emails from Paycurra about product updates, industry news, and events and I understand that I can
                            unsubscribe at any time if I change my mind.
                          </label>
                        </div>
                      </div>
                      <div>
                        <button type="submit" disabled={!isValid || isSubmitting} className={styles.bookADemoButton}>
                          Book Now
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>

            <div className="col-lg-6 d-none align-items-center d-lg-flex">
              <div className={styles.bookADemoIllustrationContainer}>
                <img src={bookademo} alt={`Contact`} className={styles.bookADemoImage} />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.successContainer}>
            <div className={styles.imgContainer}>
              <img src={SuccessImg} className="img-fluid d-block mx-auto" />
            </div>
            <div className={styles.successText}>Your demo booking was successful!</div>
            <div className={styles.details}>We will contact you through email</div>
          </div>
        )}
      </div>
    </section>
  );
};

export default BookADemoPage;
