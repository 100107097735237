import * as React from "react";
import { IconButton, Dialog } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import paycurra from "../../Assets/images/paycurra-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { NavBarBox } from "./header.styles";
import BookADemo from "../BookADemo";
import { CloseIcon } from "../../Assets/svgs";

const pages = [
  { link: "/features", name: "Features" },
  { link: "/pricing", name: "Pricing" },
  // { link: "/insights", name: "Insights" },
  { link: "/integration", name: "Integration" },
  // { link: "/paycurra", name: "Paycurra" },
];

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openContact, setOpenContact] = React.useState(false);

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleRoute = (link: string, name: string) => {
    if (name === "Features") {
      navigate(link, { state: { goTo: "feature" } });
    } else {
      navigate(link);
    }

    handleMenuToggle();
  };

  const handleMenuToggle = () => setOpenMenu(!openMenu);

  const handleRouteToContact = () => {
    // setOpenContact(true);
    navigate("/book-a-demo");
    handleMenuToggle();
  };

  return (
    <NavBarBox>
      {/* <NavBar /> */}
      <div className="container">
        <div className="d-flex align-items-center">
          <Link to="/">
            <img src={paycurra} alt="" width="80%" />
          </Link>

          <IconButton className="d-lg-none ms-auto" size="large" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenuToggle}>
            <MenuIcon />
          </IconButton>

          <div className={`menuContainer ${openMenu ? "visible" : "hidden"}`}>
            {openMenu && (
              <IconButton className="closeContainer" onClick={handleMenuToggle}>
                <CloseIcon />
              </IconButton>
            )}

            <ul className="navMenu mx-auto">
              {pages.map((item, index) => (
                <li
                  key={`${item.name}-${index}`}
                  onClick={() => {
                    handleRoute(item.link, item.name);
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>

            <button className="getBtn" onClick={handleRouteToContact}>
              Contact Sales
            </button>
          </div>
        </div>

        {/* <Dialog open={openContact} onClose={() => setOpenContact(false)}>
          <BookADemo handleClose={() => setOpenContact(false)} />
        </Dialog> */}

        {/* <Toolbar
          disableGutters
          sx={{
            justifyContent: "space-between",
            px: { md: "36px", xs: 0 },
          }}
        >
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src={paycurra} alt="" width="80%" />
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <img src={paycurra} alt="" width="40%" />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    sx={{
                      color: "#150200",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 20,
                    }}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  display: "block",
                  color: "#150200",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: 18,
                  textTransform: "capitalize",
                  mx: "15px",
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Button
              component={Link}
              to="/book-a-demo"
              variant="outlined"
              sx={{
                color: "#150200",
                fontWeight: 400,
                fontFamily: "Poppins",
                textTransform: "capitalize",
                fontSize: "18px",
                borderRadius: "4px",
                border: "1px solid #150200",
                padding: "10px 40px",
              }}
            >
              Get Paycurra
            </Button>
          </Box>
        </Toolbar> */}
      </div>
    </NavBarBox>
  );
}
export default ResponsiveAppBar;
