import { styled } from "@mui/material";

export const DoMoreBox = styled("section")(({ theme }) => ({
  background: "#FAFAFA",
  padding: "5rem 0",

  "& .SectionTitle": {
    color: "#150200",
    fontSize: "2.65rem",
    fontWeight: 600,
    fontFamily: "Poppins",
    marginBottom: "5rem",

    "& span": {
      fontFamily: "Merienda",
      color: "#FF5349",
    },
  },

  "& .textSection": {
    "& .title": {
      fontFamily: "Poppins",
      fontSize: "2.35rem",
      fontWeight: 600,
      color: "#150200",
      lineHeight: 1.45
    },

    "& .details": {
      margin: "1rem 0 2.2rem",
    },

    "& .details, .list li": {
      color: "#454242",
      fontSize: "1.25rem",
      fontWeight: 400,
      fontFamily: "Montserrat",
    },

    "& .list": {
      listStyle: "none",
      padding: 0,
      display: "flex",
      flexDirection: "column",
      gap: "1.25rem 0",

      "& li": {
        fontSize: "1rem",
        display: "flex",
        alignItems: "center",
        gap: "0 1rem",

        "& svg": {
          minWidth: "8px",
          minHeight: "8px",
          width: "8px",
          height: "8px",
        },
      },
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:1199.9px)": {
    "& .SectionTitle": {
      fontSize: "2.35rem",
    },

    "& .textSection": {
      "& .title": {
        fontSize: "2.15rem",
      },

      "& .details, .list li": {
        fontSize: "1.05rem",
      },
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:767.9px)": {
    "& .SectionTitle": {
      fontSize: "2.15rem",
    },

    "& .textSection": {
      "& .title": {
        fontSize: "1.9rem",
      },

      "& .details, .list li": {
        fontSize: "0.95rem",
      },

      "& .list": {
        "& li": {
          "& svg": {
            minWidth: "15px",
            minHeight: "15px",
            width: "15px",
            height: "15px",
          },
        },
      },
    },
  },
}));
