import { styled } from "@mui/material";

export const LoyaltyBox = styled("section")(({ theme }) => ({
  padding: "5rem, 0",

  "& .textHolder": {
    "& .title": {
      fontFamily: "Poppins",
      fontSize: "2.375rem",
      color: "#150200",
      fontWeight: 600,
    },

    "& .details": {
      fontFamily: "Montserrat",
      fontSize: "1.15rem",
      color: "#454242",
      fontWeight: 400,
    },
  },

  "& .offerList": {
    marginBottom: "5rem",

    "& .title": {
      color: "#150200",
      fontFamily: "Poppins",
      fontSize: "1.25rem",
      fontWeight: 500,
    },

    "& .details": {
      color: "#847F7F",
      fontFamily: "Montserrat",
      fontSize: "1rem",
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:1199.9px)": {
    padding: "4rem, 0",

    "& .textHolder": {
      "& .title": {
        fontSize: "2.15rem",
      },

      "& .details": {
        fontSize: "1.05rem",
      },
    },

    "& .offerList": {
      "& .title": {
        fontSize: "1.15rem",
      },

      "& .details": {
        color: "#847F7F",
        fontFamily: "Montserrat",
        fontSize: "0.9rem",
      },
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:767.9px)": {
    padding: "3rem, 0",

    "& .textHolder": {
      "& .title": {
        fontSize: "2rem",
      },

      "& .details": {
        fontSize: "1rem",
      },
    },

    "& .offerList": {
      marginBottom: "3rem",

      "& .title": {
        fontSize: "1.05rem",
      },

      "& .details": {
        fontSize: "0.875rem",
      },
    },
  },
}));
