import { StarIcon, MeasureIcon, GiftIcon } from "../../../../Components/Home/Components/icons";
import { Box } from "@mui/material";
import { LoyaltyBox } from "./styles";

export const LoyaltyProgram = () => {
  return (
    <LoyaltyBox>
      <div className="col-xl-9 col-md-10 my-5">
        <div className="textHolder">
          <h4 className="title"> Boost your customer engagement with promotional campaigns and loyalty rewards.</h4>
          <p className="details mt-3">
            Encourage repeat customer behaviours: engage and delight your <br className="d-none d-lg-block" />  customers with discounts, cashbacks, and loyalty points.
          </p>
        </div>
      </div>

      <div className="row pt-5">
        <div className="col-md-4 offerList">
          <Box sx={{ background: "rgba(255, 234, 194, 0.5)" }} className="p-2 d-inline-flex mb-3 rounded">
            <StarIcon />
          </Box>
          <h5 className="title">Powerful rules engine</h5>
          <p className="details">
            Define promotion rules based on customer profile attributes, cart total value, cart item value, product sku and product category and more.
          </p>
        </div>

        <div className="col-md-4 offerList">
          <Box sx={{ background: "rgba(222, 189, 255,0.5)" }} className="p-2 d-inline-flex mb-3 rounded">
            <MeasureIcon />
          </Box>
          <h5 className="title">Measure programme KPIs</h5>
          <p className="details">
            Monitor promotional spend against campaign budget, track campaign KPIs including: Customer Lifetime Value (CLV), Customer Retention Rate
            (CRR), Customer Acquisition Rate (CAR).
          </p>
        </div>

        <div className="col-md-4 offerList">
          <Box sx={{ background: "rgba(255, 104, 84, 0.5)" }} className="p-2 d-inline-flex mb-3 rounded">
            <GiftIcon />
          </Box>
          <h5 className="title">Omnichannel capable loyalty</h5>
          <p className="details">Allow your customers to earn and redeem rewards online and in-store with our Loyalty APIs and POS Integrations.</p>
        </div>
      </div>
    </LoyaltyBox>
  );
};
