import NavBar from "../../Components/Header";
import Footer from "../../Components/Footer";
import Styles from "./terms.module.css";
import * as React from "react";
import { useLocation } from "react-router-dom";

export const TermsAndCondition = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NavBar />
      <section className="py-5">
        <div className="container">
          <h3 className={Styles.title}>Paycurra Terms and Conditions</h3>

          <section className={Styles.text__holder}>
            <p className={Styles.section__details}>
              Thank you for your interest in Paycurra. The Paycurra Rewards Programme (referred to as the "Rewards Programme") is provided by
              Cognitive Labs Limited ("Cognitive Labs") through the Paycurra mobile app and Paycurra.com (collectively known as the "Paycurra
              Platform"). This Rewards Programme agreement ("Agreement") serves as a contract between you and Paycurra, overseeing your access to and
              use of the Rewards Programme.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>Acceptance and Eligibility</h5>
            <p className={Styles.section__details}>
              By participating in the Rewards Programme on the Paycurra Platform, you agree to this Agreement.
            </p>
            <ul className={Styles.list__group}>
              <li>
                To join, you must have an active Paycurra account in good standing and be at least 16 years old, residing in Nigeria or its
                territories.
              </li>
              <li>
                You affirm that you have the legal capacity to enter into this Agreement when using the Rewards Programme. Availability may vary by
                location, and you agree not to participate if it violates local laws.
              </li>
              <li>
                The Rewards Programme is for individual, non-commercial use only. You must not use it for third parties or commercial purposes. You
                also agree not to create multiple Paycurra accounts to evade restrictions or accumulate excessive Points.
              </li>
              <li>
                You must adhere to Paycurra's decisions regarding the Rewards Programme, and Paycurra has the authority to take action in cases of
                misuse, fraud, or violations of these terms, including suspending or terminating accounts and revoking accrued benefits.
              </li>
            </ul>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>Description of the Rewards Programme</h5>
            <p className={Styles.section__details}>
              The Paycurra Rewards Programme allows users to discover online shopping deals, coupons, discount codes, free gifts and exclusive offers
              for potential savings when shopping online. As described in more details below, using Paycurra offers you a chance to accumulate Points.
              <br /> <br />
              Participating in the Rewards Programme through Paycurra incurs no fee charges at present.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>Earning Points</h5>
            <p className={Styles.section__details}>
              We grant you access to our rewards points through the Paycurra Platform based on specific actions. You can earn Points primarily by
              making eligible purchases from partner merchants via the Paycurra Checkout Web or Mobile App.
              <br />
              <br />
              Points availability varies by merchant and product. When eligible, we will indicate this on the Paycurra Platform. You can accrue Points
              by following prompts within the Paycurra Platform, such as adding rewards to your wallet, applying them to your checkout cart, and
              completing eligible purchases.
              <br />
              <br />
              Earning Points is subject to this Agreement and additional terms during offers. You can earn Points through one method per qualifying
              purchase, and combining methods in a single transaction won't yield extra Points.
              <br />
              <br />
              Points can be calculated as a fixed percentage of the eligible item subtotal or as a fixed Point count. Points may exclude tax or fees.
              You can earn Points through various means, and some items or categories might be excluded.
              <br />
              <br />
              Returning items or receiving full refunds will result in a deduction of Points. If you return items or get refunds, Paycurra may revoke
              the Points earned, especially if obtained fraudulently.
              <br />
              <br />
              If you have questions about earning Points, please contact us at hello@paycurra.com or raise a dispute through your mobile app. We may
              require proof such as order confirmation or order number to resolve issues.
              <br />
              <br />
              Points cannot be purchased, are not your property, and have no value until redeemed. Additional rules may apply upon redemption. Points
              cannot be transferred except as explicitly allowed within the Rewards Programme rules.
              <br />
              <br />
              You understand that benefits from using Points may be taxable, and you are responsible for any associated tax liability.
              <br />
              <br />
              Paycurra reserves the right to modify, terminate, or limit the Rewards Programme at any time, including retroactively for previously
              earned Points. We may set limits on Points, refuse to grant them, or change how they are earned or used without prior notice.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>Redeeming Points</h5>
            <p className={Styles.section__details}>
              If your account remains in good standing, you can use Points through various methods, which we will detail below.
              <br /> <br />
              The Paycurra Platform will specify available redemption options and their corresponding Point requirements. Redemption categories may
              vary, depending on availability and specific conditions. Once you redeem Points, your choices are final. There may be additional
              restrictions on Points redemption. We reserve the right to change Point requirements, redemption rates, and item availability at our
              discretion, without prior notice. We may also introduce new redemption options within the Rewards Programme from time to time.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>Redemption for Cash</h5>
            <p className={Styles.section__details}>Points made cannot be redeemed for cash</p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>Redemption at Checkout</h5>
            <p className={Styles.section__details}>
              Participating merchants allow you to use Points and other rewards during Paycurra account checkout. If available, you can apply Points
              to cover part of the cost of eligible purchases. You can redeem any amount of Points unless Paycurra specifies otherwise during
              checkout. When redeemed, Points convert to cash to offset the purchase price, with their value set by Paycurra at checkout. This method
              applies to eligible purchases from Nigerian merchants and covers the total purchase price. When using Points for the entire purchase,
              you must have a backup payment method. Your backup payment method may be charged in payment disputes, for costs beyond redeemed Points
              (e.g., shipping fees), or in specific cases.
              <br /> <br />
              If a transaction you used Points for is fully refunded or reversed, the used Points are credited back to your Points balance. In cases
              of partial refunds for transactions paid with both Points and another method, the other method is refunded first, up to the original
              amount paid, with any excess returned to your Points balance.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>Expiration &amp; Loss of Rewards and Points; Termination of the Programme</h5>
            <p className={Styles.section__details}>
              To retain your Points, keep your Paycurra account active. If your account remains inactive for twelve consecutive months (e.g., from
              April 10 this year to April 10 next year), Paycurra may reset your Points to zero, rendering unredeemed Points unusable. In the event of
              a dispute over Points expiration, Paycurra's decision is final. If your Paycurra account is closed, any unredeemed Points earned through
              Paycurra become invalid.
              <br /> <br />
              Paycurra can suspend or terminate your participation or account if it suspects abuse, violation of this Agreement, or actions
              detrimental to its interests. This includes using automated or fraudulent methods, disregarding the Agreement, attempting to buy or sell
              Points, or providing false information. In case of termination, all your Points and unused rewards are forfeited.
              <br /> <br />
              Paycurra partner merchants also have the authority to cancel, terminate, modify, or restrict their individual Rewards Programme or
              benefits at any time. If a merchant programme concludes, Paycurra will notify you, typically with at least 21 days' notice, allowing you
              to use your accumulated Points during this period. If participants transition to a successor programme, their unexpired Points remain
              valid.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>Amendments of this Agreement</h5>
            <p className={Styles.section__details}>
              Paycurra has the right to update this Agreement and other terms related to your participation in the Rewards Programme. Any changes will
              be posted on Paycurra's website, showing the revision date. The updated Agreement becomes effective when posted or as indicated. If you
              do not agree with the changes, you should stop participating. If you continue to use the Rewards Programme after changes are made,
              you're agreeing to those changes.
            </p>
          </section>

          <section className={Styles.text__holder}>
            <h5 className={Styles.section__title}>Assignment</h5>
            <p className={Styles.section__details}>
              Paycurra has the authority to transfer, without any recourse, its rights or duties outlined in this Agreement. This includes
              transferring the entirety or a portion of this Agreement, as well as any responsibilities concerning Points. Should Paycurra decide to
              transfer these responsibilities, the assignee will become the sole entity responsible for those Points, and Paycurra will no longer have
              any related obligations.
            </p>
          </section>
        </div>
      </section>
      <Footer />
    </>
  );
};
