import AppHead from "../../Components/Head";
import Layout from "../../Components/Layouts";
import React from "react";
import Navbar from "../../Components/Header";
import styles from "./styles.module.css";
import HeaderImg from "../../Assets/images/integration-header-bg.svg";
import { CartIcon, BankIcon, TravelIcon } from "../../Components/Home/Components/icons";
import { Box } from "@mui/material";
import CheckoutViewImg from "../../Assets/images/paycurra-checkout.svg";
import CheckoutLoginViewImg from "../../Assets/images/paycurra-checkout-login.svg";
import CodeSnippetImg from "../../Assets/images/code-snippet.svg";
import CustomerViewImg from "../../Assets/images/customer-view-img.svg";
import { Seo } from "../../Components/Seo";

export const Integration = () => {
  return (
    <>
      <Seo
        title="Integrate with Paycurra"
        description="Integrate in 3 steps to give your customers rewarding ways to pay."
        keywords="paycurra, integration, merchant, onboarding, checkout reward, customer, loyalty, campaign, bonus, gift, discount, coupons, boost, sales, attract, retain, build, brand customer, engage customer"
        image={CodeSnippetImg}
      />

      <Layout>
        {/* <AppHead title={"Welcome"} children={undefined} /> */}
        <Navbar />
        <header className="container my-lg-4">
          <div className="row">
            <div className="col-lg-6">
              <div className={`${styles.headerText} mt-lg-5 pt-5`}>
                <h3 className={`${styles.title} mb-3`}>Paycurra checkout</h3>
                <p className={`${styles.details} mb-5`}>
                  Integrate in 3 steps to give your customers rewarding ways to pay. Our platform includes a comprehensive range of features, such as
                  saved payment cards, wallet rewards, and personalized deals reserved for your online store. Plus, customers can easily view their
                  earned rewards in real-time.
                </p>
                <div className={styles.textHolder}>
                  <h3 className={`${styles.title} mb-3`}>Step 1</h3>
                </div>
                <ul>
                  <li>
                    Our team offers free integration consultation, technical guidance, and implementation to help you get started without any
                    additional cost.
                  </li>
                  <li>
                    Additionally, you can bring your own preferred payment gateway, our low-code integration and built-in support for Paystack, Stripe
                    and other payment gateways enables you integrate without lock-in.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start">
              <div className="position-relative mt-lg-5">
                <div className={styles.number}>1</div>
                <img src={HeaderImg} className="img-fluid" alt="Checkout merchant" />
              </div>
            </div>
          </div>
        </header>

        <section className={`${styles.grayBg} ${styles.sectionPadding}`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className={styles.textHolder}>
                  <h3 className={`${styles.title} mb-3`}>Step 2</h3>

                  <ul>
                    <li>
                      Once your web site is integrated, customers that opt to checkout with Paycurra would be presented with a secure login page for a
                      protected payment process.
                    </li>
                    <li>
                      Once your promotions are approved and published, they will be accessible on the Paycurra Wallet app. Users can easily reserve
                      their deals and rewards to enjoy savings or earnings when making purchases through Paycurra on your integrated website.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center justify-content-lg-start">
                <div className="position-relative">
                  <div className={styles.number}>2</div>
                  <img src={CustomerViewImg} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={` ${styles.sectionPadding}`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className={styles.textHolder}>
                  <h3 className={`${styles.title} mb-3`}>Step 3</h3>
                  <ul>
                    <li>The Paycurra Merchant onboarding team will assist you in setting up your account.</li>
                    <li>
                      Once you're up and running, you'll be able to design captivating promotional campaigns and loyalty programmes on the platform.
                      This includes coupons, points, stamps, and cashbacks.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.imgHolder}>
                  <div className={styles.number}>3</div>
                  <div className={styles.img1}>
                    <img src={CodeSnippetImg} className="img-fluid" />
                  </div>
                  <div className={styles.img2}>
                    <img src={CheckoutViewImg} className="img-fluid" />
                  </div>
                  <div className={styles.img3}>
                    <img src={CheckoutLoginViewImg} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`${styles.grayBg} ${styles.sectionPadding}`}>
          <div className="container">
            <div className="col-xl-8 col-md-10 my-5">
              <div className={styles.textHolder}>
                <h4 className={`${styles.title} mb-3`}> Build customer engagement experiences that are tailored to your industry.</h4>
                <p className={`${styles.details} mb-3`}>
                  Whether you are a fintech insurgent, an incumbent bank or a retail store, integrate with the Paycurra Commerce Platform today for
                  solutions to help you attract and retain customers with personalised offerings.
                </p>
              </div>
            </div>

            <div className="row pt-5">
              <div className={`col-md-4 ${styles.offerList}`}>
                <Box sx={{ background: "#EBF4FF" }} className="p-2 d-inline-flex mb-3 rounded">
                  <CartIcon />
                </Box>
                <h5 className={styles.title}>Ecommerce &amp; Retail</h5>
                <p className={styles.details}>
                  Give rewards on first visit and on birthdays, incentivise based on cart attributes, increase order value with realtime
                  recommendations and use gamification to drive repeat behaviours.
                </p>
              </div>

              <div className={`col-md-4 ${styles.offerList}`}>
                <Box sx={{ background: "rgba(3, 122, 106, 0.20)" }} className="p-2 d-inline-flex mb-3 rounded">
                  <BankIcon />
                </Box>
                <h5 className={styles.title}>Banks &amp; Fintechs</h5>
                <p className={styles.details}>
                  Award customers points based on account balance, transaction attributes, or card usage. And create winning Cashback loyalty
                  programmes to incentivise targeted payment options (e.g. BNPL).
                </p>
              </div>

              <div className={`col-md-4 ${styles.offerList}`}>
                <Box sx={{ background: "#EEE5F5" }} className="p-2 d-inline-flex mb-3 rounded">
                  <TravelIcon />
                </Box>
                <h5 className={styles.title}>Travel &amp; Hospitality</h5>
                <p className={styles.details}>
                  Increase repeat business with a tiered system targeting diverse customer segments. Build rules offering free hotel stays or
                  collaborate with partners in travel industry for flight class upgrades.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
