import React, { useEffect, useState } from "react";
import welcome from "./welcome.module.css";
import styles from "../../../Styles/Home.module.css";
import WelcomeItem from "./WelcomeItem";
import heroi from "../../../Assets/images/heroi.png";
import heroii from "../../../Assets/images/heroii.png";
import { Link } from "react-router-dom";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { HeroHeaderDescription, HeroHeaderText } from "../../Typography";
import { SolidButton } from "../../Buttons";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../TabPanel";
import { HeaderBox } from "./header.styles";
import PaycurraMockUpImg from "../../../Assets/images/checkout-mockup.png";
import MobileMockUpImg from "../../../Assets/images/mobile-mock.png";
import Navbar from "../../Header";
import { useNavigate } from "react-router-dom";
import TypingAnimation from "../../TypingAnimation";

const contents: any = [
  {
    id: 1,
    key: "merchant",
    title: "Your One Stop Platform To Gain And Retain",
    name: "Customers",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmodle mpor incididunt ut labore et dolore magnaa iqua. Ut enim ad minim venia mdolore magnaa liqua",
    image: heroi,
  },
  {
    id: 2,
    key: "consumers",
    title: "Your One Stop Platform To Gain And Retain",
    name: "Customers",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmodle mpor incididunt ut labore et dolore magnaa iqua. Ut enim ad minim venia mdolore magnaa liqua",
    image: heroii,
  },
];

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Welcome = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setTab(newValue);
  };

  const handleDownloadRoute = () => navigate("/download-app");

  const handleRouteToMerchant = () => {
    window.open("https://merchants.paycurra.com", "_blank");
  };

  return (
    <HeaderBox>
      <Navbar />
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-6 h-100 d-flex align-items-center">
            <div className="textHolder">
              <h2 className="title">
                Enable more ways to pay.
                <br />
                Attract, engage, retain.
                <br />
                Build brand loyalty.
              </h2>
              <p className="details">
                Businesses in emerging markets, use Paycurra to reward customer behaviour, enable delightful payment experiences and accelerate
                growth.
              </p>
              <div className="btnContainer">
                <SolidButton my="30px" onClick={handleRouteToMerchant}>
                  Try Merchants app
                </SolidButton>
                <SolidButton type="outline" onClick={handleDownloadRoute}>
                  Download mobile app
                </SolidButton>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="imageHolder">
              <div className="checkoutMock">
                <img src={PaycurraMockUpImg} className="img-fluid" alt="Paycurra checkout mock up" />
              </div>
              <img src={MobileMockUpImg} className="mobile" alt="Paycurra mobile mock up" />
            </div>
          </div>
        </div>
      </div>

      {/* <Container maxWidth="xl">
        <Box sx={{ padding: "30px" }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="Hero-page-tab"
            sx={{
              color: "red",
              "&	.MuiTabs-indicator": {
                backgroundColor: "#00AE97",
              },
              "& 	button": {
                color: "#847F7F",
                fontFamily: "Poppins",
                textTransform: "capitalize",
                fontSize: "17px",
                fontWeight: 400,
              },
              "& .Mui-selected": {
                color: "black !important",
              },
            }}
          >
            <Tab label="Merchant" {...a11yProps("merchant")} />
            <Tab label="Consumers" {...a11yProps("consumers")} />
          </Tabs>

          {contents.map((content: any, index: any) => (
            <TabPanel value={tab} index={content.id - 1} key={content.key}>
              <Grid
                container
                display="flex"
                direction="row"
                sx={{
                  padding: "   0",
                  // textAlign: "center",
                  flexDirection: { xs: "column-reverse", md: "row" },
                  alignItems: { xs: "center", md: "center" },
                  // justifyContent: { md: "center" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    marginBottom: { xs: "32px", md: 0 },
                    marginRight: { md: "64px" },
                    textAlign: { md: "left" },
                  }}
                >
                  <HeroHeaderText>
                    {content.title}{" "}
                    <span
                      style={{
                        color: "#FF5349",
                        fontWeight: "bold",
                      }}
                    >
                      {content.name}
                    </span>
                  </HeroHeaderText>
                  <HeroHeaderDescription>{content.description}</HeroHeaderDescription>
                  <SolidButton my="30px">Get Paycurra</SolidButton>
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: { md: "32px", xs: "12px" } }}>
                  <img src={content.image} alt="Hero" width="100%" />
                </Grid>
              </Grid>
            </TabPanel>
          ))}
        </Box>
      </Container> */}
    </HeaderBox>
  );
};

export default Welcome;
