import { styled } from "@mui/material";

export const WhyPaycurraBox = styled("section")(({ theme }) => ({
  padding: "5rem 0",

  "& .textHolder": {
    "& .title": {
      fontFamily: "Poppins",
      fontSize: "2.375rem",
      color: "#150200",
      fontWeight: 600,
    },

    "& .details": {
      fontFamily: "Montserrat",
      fontSize: "1.15rem",
      color: "#454242",
      fontWeight: 400,
    },
  },

  "& .reasonContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "2rem 0",
    listStyle: "none",
    padding: "1.5rem 0 3rem",
    margin: 0,

    "& .reasonHolder": {
      borderBottom: "1px solid rgba(132, 127, 127, 0.30)",
      padding: "0 0 1.5rem",

      "& .title": {
        color: "#150200",
        fontSize: "1.5rem",
        fontWeight: 500,
      },

      "& .details": {
        color: "#454242",
        fontSize: "1.15rem",
        fontFamily: "Montserrat",
        fontWeight: 400,
        margin: "0.5rem 0",
      },

      "& a": {
        fontSize: "1rem",
        color: "#FF5349",
        textDecoration: "none",
        fontWeight: 600,
        display: "inline-flex",
        gap: "0 0.5rem",
      },

      "&:last-child": {
        borderBottom: "none",
      },
    },
  },

  "& .offerList": {
    marginBottom: "5rem",

    "& .title": {
      color: "#150200",
      fontFamily: "Poppins",
      fontSize: "1.25rem",
      fontWeight: 500,
    },

    "& .details": {
      color: "#847F7F",
      fontFamily: "Montserrat",
      fontSize: "1rem",
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:1199.9px)": {
    padding: "4rem, 0",

    "& .textHolder": {
      "& .title": {
        fontSize: "2.15rem",
      },

      "& .details": {
        fontSize: "1.05rem",
      },
    },

    "& .reasonContainer": {
      padding: "2rem 0 4rem",

      "& .reasonHolder": {
        "& .title": {
          fontSize: "1.35rem",
        },

        "& .details": {
          fontSize: "1rem",
          margin: "0.75rem 0",
        },

        "& a": {
          fontSize: "0.95rem",
          gap: "0 0.35rem",
        },
      },
    },

    "& .offerList": {
      "& .title": {
        fontSize: "1.15rem",
      },

      "& .details": {
        color: "#847F7F",
        fontFamily: "Montserrat",
        fontSize: "0.9rem",
      },
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:767.9px)": {
    padding: "3rem, 0",

    "& .textHolder": {
      "& .title": {
        fontSize: "2rem",
      },

      "& .details": {
        fontSize: "1rem",
      },
    },

    "& .reasonContainer": {
      padding: "2rem 0 4rem",

      "& .reasonHolder": {
        "& .title": {
          fontSize: "1.35rem",
        },

        "& .details": {
          fontSize: "1rem",
          margin: "0.75rem 0",
        },

        "& a": {
          fontSize: "0.95rem",
          gap: "0 0.35rem",
        },
      },
    },

    "& .offerList": {
      marginBottom: "3rem",

      "& .title": {
        fontSize: "1.05rem",
      },

      "& .details": {
        fontSize: "0.875rem",
      },
    },
  },
}));
