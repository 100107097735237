import AppHead from "../../Components/Head";
import Layout from "../../Components/Layouts";
import Welcome from "../../Components/Home/Welcome";
import WhoWeAreFor from "../../Components/Home/WhoWeAreFor";
import Features from "../../Components/Home/Features";
import Deals from "../../Components/Home/Deals";
import Testimonials from "../../Components/Home/Testimonials";
import React, { useEffect } from "react";
import { WhyPaycurra, DoMoreWithPaycurra, AppDownload } from "./components";
import { scroller } from "react-scroll";
import { useParams } from "react-router-dom";
import { Seo } from "../../Components/Seo";
import PaycurraLogo from "../../Assets/images/paycurra-logo-v2.png"

export const Home = () => {
  const params = useParams();

  console.log(params)

  useEffect(() => {
    if (params && params.feature) {
      handleGoToFeatures();
    }
  }, [params]);

  const handleGoToFeatures = () => {
    scroller.scrollTo("featureSection", {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -70, // Adjust as needed
    });
  };

  return (
    <>
      <Layout>
        <Seo
          title="Paycurra enable more ways to pay. attract, engage, retain customer by building brand loyalty"
          description="Paycurra enable more ways to pay. Attract, engage, retain customer by building brand loyalty | Paycurra"
          keywords="paycurra, checkout, reward, customer, loyalty, campaign, bonus, gift, discount, boost, sales, attract, retain, build, brand customer, engage customer"
          image={PaycurraLogo}
        />
        {/* <AppHead title={"Welcome"} children={undefined} /> */}
        <Welcome />
        <WhyPaycurra />
        <DoMoreWithPaycurra />
        <AppDownload />
        {/* <WhoWeAreFor /> */}
        {/* <Audience /> */}
        {/* <Features /> */}
        {/* <Deals /> */}
        {/* <Testimonials /> */}
      </Layout>
    </>
  );
};
