import { DoMoreBox } from "./doMore.styles";
import { CircleListIcon } from "../../../../Assets/svgs";
import TrendAnalticsImg from "../../../../Assets/bg/trend-analytics-bg.svg";
import RetainCustomerImg from "../../../../Assets/bg/retain-customer-bg.svg";
import WalletMgtImg from "../../../../Assets/bg/wallet-mgt-bg.svg";

export const DoMoreWithPaycurra = () => {
  return (
    <DoMoreBox>
      <div className="container">
        {/* <h3 className="SectionTitle">
          Do More With <span>Paycurra</span>
        </h3> */}

        <div className="row my-5" id="featureSection">
          <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
            <div className="textSection">
              <h4 className="title">Boost your sales with personalised offerings built from rich profiles and custom attributes.</h4>
              <p className="details">
                Utilize the power of data to personalize your customer interactions. Improve your revenue by deciphering your customers' buying habits
                and preferences with data analysis.
              </p>

              <ul className="list">
                <li>
                  {/* <CircleListIcon /> */}
                  <div>
                    <strong>Data driven engagements</strong>
                    <div>
                      Maximize customer interactions with data-driven Insights: leveraging segmentation, analytics, and actionable insights, to
                      deliver targeted messaging and achieve better results.
                    </div>
                  </div>
                </li>
                <li>
                  {/* <CircleListIcon /> */}
                  <div>
                    <strong>Personalised commerce </strong>
                    <div> Customize customer interactions by analysing their demographics and past behaviour.</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <img src={TrendAnalticsImg} className="img-fluid" />
          </div>
        </div>

        <div className="row my-5">
          <div className="col-lg-6">
            <img src={RetainCustomerImg} className="img-fluid" />
          </div>

          <div className="col-lg-6 d-flex align-items-center">
            <div className="textSection">
              <h4 className="title">Plan campaigns, set goals and track progress. </h4>
              <p className="details">
                Accelerate your business growth with engaging rewards and loyalty programmes that captivate and build long-lasting customer
                relationships.
              </p>

              <ul className="list">
                <li>
                  {/* <CircleListIcon /> */}
                  <div>
                    <strong>Sell smart, sell more</strong>
                    <div>
                      Transform casual shoppers into loyal customers by using our promotional campaigns and loyalty capabilities. Offer irresistible
                      incentives that will keep them coming back for more, and watch your customer base grow.
                    </div>
                  </div>
                </li>
                <li>
                  {/* <CircleListIcon /> */}
                  <div>
                    <strong>Track campaigns</strong>
                    <div>
                      Monitor the progress of your promotional campaigns and loyalty programmes. From engagement metrics to conversion rates, gain
                      insights into what's working best.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row my-5">
          <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
            <div className="textSection">
              <h4 className="title">Checkout solution with payment orchestrations for your provider of choice without any lock-in. </h4>
              {/* <p className="details">The Paycurra wallet enables seamless payments for purchases through integrated payment gateways.</p> */}

              <ul className="list">
                <li>
                  <CircleListIcon />
                  Integrate your existing payment gateway accounts: Paystack, Stripe, and more.
                </li>
                <li>
                  <CircleListIcon />
                  Review checkout session status, checkout earnings and rewards.
                </li>
                <li>
                  <CircleListIcon />
                  Surface customer’s wallet reserved rewards during checkout journey: discount coupons, stamps, points and more.
                </li>
                <li>
                  <CircleListIcon />
                  Recover abandoned carts and setup customer email notifications.
                </li>
                <li>
                  <CircleListIcon />
                  Save tokenised card payment methods for easy recharging
                </li>
                <li>
                  <CircleListIcon />
                  Realtime personalised rewards and cross-sell recommendations
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <img src={WalletMgtImg} className="img-fluid" />
          </div>
        </div>
      </div>
    </DoMoreBox>
  );
};
