import React from "react";
import Footer from "../Footer";
import Navbar from "../Header";

const Layout = ({ children }: any) => {
  return (
    <main className="">
      {/* <Navbar /> */}
      {children}
      <Footer />
    </main>
  );
};

export default Layout;
