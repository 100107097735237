import { DownloadBox } from "./appDownload.styles";
import PlayStoreImg from "../../../../Assets/images/Google-Play.svg";
import AppStoreImg from "../../../../Assets/images/App-Store.svg";
import TopPhoneImg from "../../../../Assets/bg/phone-top-bg.svg";
import BottomPhoneImg from "../../../../Assets/bg/phone-bottom-bg.svg";

export const AppDownload = () => {
  return (
    <DownloadBox>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="textHolder">
              <h3 className="title">
                Paycurra commerce platform provides retailers, e-wallets, and banks with the tools to attract and engage customers.
              </h3>
              {/* <p className="details">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmodte mpor incididunt ut labore et dolore magnaa liqua. Ut enim ad
                minim venia mdolore magnaa liqua.
              </p> */}

              <div className="d-flex gap-3 flex-wrap downloadBtnContainer">
                <img src={PlayStoreImg} className="img-fluid" />
                <img src={AppStoreImg} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="imgContainer">
              <img src={TopPhoneImg} className="img-fluid img1" />
              <img src={BottomPhoneImg} className="img-fluid img2" />
            </div>
          </div>
        </div>
      </div>
    </DownloadBox>
  );
};
