import { styled } from "@mui/material";

export const NavBarBox = styled("nav")(({ theme }) => ({
  // background: "radial-gradient(50% 50.00% at 50% 50.00%, rgba(248, 188, 185, 0.20) 0%, rgba(244, 50, 73, 0.00) 100%)",
  padding: "2rem 0",

  "& .menuContainer": {
    display: "flex",
    width: "100%",
    alignItems: "center",

    "& .navMenu": {
      display: "flex",
      gap: "0 2.5rem",
      listStyle: "none",
      margin: "0 auto",
      padding: 0,

      "& li": {
        color: "#150200",
        fontSize: "1.15rem",
        fontWeight: 400,
        textDecoration: "none",
        cursor: "pointer",
      },
    },

    "& .getBtn": {
      border: "1px solid #150200",
      borderRadius: "4px",
      background: "transparent",
      fontWeight: 500,
      padding: "0.8rem 1.6rem",
      color: "#150200",
      transition: ".2s all ease-in-out",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },

    "& .closeContainer": {
      display: "none",
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:991.9px)": {
    position: "relative",

    "& .menuContainer": {
      flexDirection: "column",
      position: "fixed",
      borderRadius: "8px",
      backgroundColor: "rgba(255,255,255,0.975)",
      backDropFilter: "blur(10px)",
      top: 0,
      right: 0,
      zIndex: 10,
      padding: "2rem",
      minWidth: "200px",
      //   Make initially invisible
      height: "100vh",
      visibility: "hidden",
      opacity: 0,
      transition: "visibility 0s linear 0.3s, opacity 0.3s linear",
      justifyContent: "center",

      "& .navMenu": {
        flexDirection: "column",
        gap: "2rem 0",
        margin: 0,
        textAlign: "center",

        "& li": {
          fontSize: "1.15rem",
        },
      },

      "& .getBtn": {
        marginTop: "2rem",
      },
    },

    "& .visible": {
      opacity: 1,
    },

    "& .closeContainer": {
      display: "inline-block !important",
      position: "absolute",
      top: "2.5rem",
      right: "1.5rem",
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:767.9px)": {
    padding: "2rem 0",

    "& .menuContainer": {
      "& .navMenu": {
        "& li": {
          fontSize: "1.15rem",
        },
      },
    },
  },
}));
