import NavBar from "../../Components/Header";
import Footer from "../../Components/Footer";
import Styles from "./faq.module.css";
import { useLocation } from "react-router-dom";
import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Seo } from "../../Components/Seo";
import PaycurraLogo from "../../Assets/images/paycurra-logo-v2.png"

interface IFaqData {
  id: number;
  title: string;
  faq: TFaq[];
}

type TFaq = {
  qId: number;
  question: string;
  answer: string;
};

const faqData: IFaqData[] = [
  {
    id: 1,
    title: "Disputes and Refund Claims",
    faq: [
      {
        qId: 1,
        question: "How does your service gets upgraded after initial subscription expires",
        answer:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      },
      {
        qId: 2,
        question: "How does your service gets upgraded after initial subscription expires",
        answer:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      },
    ],
  },
  {
    id: 2,
    title: "Account Validity",
    faq: [
      {
        qId: 1,
        question: "How does your service gets upgraded after initial subscription expires",
        answer:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      },
      {
        qId: 2,
        question: "How does your service gets upgraded after initial subscription expires",
        answer:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      },
    ],
  },
];

export const Faq = () => {
  const [expanded, setExpanded] = React.useState<string | false>("11");
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Seo
        title="Frequently asked questions"
        description="Frequently asked questions | Paycurra"
        keywords="paycurra, question, answer, merchant, onboarding, checkout reward, customer, loyalty, campaign, bonus, gift, discount, coupons, boost, sales, attract, retain, build, brand customer, engage customer"
        image={PaycurraLogo}
      />

      <NavBar />
      <header className={Styles.headerSection}>
        <div className="container">
          <div className={Styles.headerText}>
            <h3 className={Styles.title}>Frequently Asked Questions</h3>
            <p className={Styles.details}>
              You got questions to ask us? We got you covered with some of our most frequent asked questions curated for you
            </p>
          </div>
        </div>
      </header>
      <section className={Styles.faqSection}>
        <div className="container">
          <div className="mb-5">
            <h5 className={Styles.title}>Your Account</h5>
            <>
              <Accordion expanded={expanded === `11`} onChange={handleChange(`11`)}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <p className={Styles.question}>How can I close my Paycurra account?</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                    Sorry you are choosing to close your Paycurra account.
                    <br />
                    <br />
                    Here are some steps to complete before you close your Paycurra account:
                    <br />
                    <br />
                    <ul className="mb-0">
                      <li>Raise a dispute if you have identified payments on your wallet you did not approve.</li>
                      <li>
                        Closing your account will cancel all associated rewards, offers and linked loyalty points, so if you think you will want to
                        use these in the future you may have to keep your account open.
                      </li>
                      <li>
                        Remove or spend your account balances; if you have balances on your wallet whether points-based, cashback-based or cash.
                      </li>
                    </ul>
                    <br />
                    <br />
                    To close your account on the app:
                    <br />
                    <br />
                    <ol className="mb-0">
                      <li>Tap the “More” menu icon.</li>
                      <li>Under “Personal Settings” tap “Close Account”.</li>
                      <li>Tap “Delete Account”.</li>
                    </ol>
                  </p>
                </AccordionDetails>
              </Accordion>
            </>

            <>
              <Accordion expanded={expanded === `12`} onChange={handleChange(`12`)}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <p className={Styles.question}>How can I open a dispute with a merchant?</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className={Styles.answer}>
                    We advise you contact the merchant first to try to resolve any payment problem you may be experiencing. They are usually have
                    processes in place to make a refund or a return where necessary. The merchant’s contact details will be available on your Checkout
                    Transaction email or on your Checkout Sessions page of the Paycurra App.
                    <br />
                    <br />
                    Follow the steps below to raise a dispute with the merchant against your Checkout Session on the app:
                    <br />
                    <br />
                    <ol className="mb-0">
                      <li>Tap the “More” menu icon.</li>
                      <li>Tap “Disputes”</li>
                      <li>Tap “Open Dispute” </li>
                      <li>Enter session URN of a Checkout Session transaction within the last 90 days.</li>
                      <li>Complete necessary details and tap Submit.</li>
                    </ol>
                  </p>
                </AccordionDetails>
              </Accordion>
            </>
          </div>

          {/* {faqData.map(({ title, faq, id }: IFaqData) => (
          <div className="mb-5">
            <h5 className={Styles.title}>{title}</h5>
            <>
              {faq.map(({ question, answer, qId }: TFaq) => (
                <Accordion expanded={expanded === `${id}${qId}`} onChange={handleChange(`${id}${qId}`)}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <p className={Styles.question}>{question}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>{answer}</p>
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          </div>
        ))} */}
        </div>
      </section>
      <Footer />
    </>
  );
};

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  borderWidth: 0,
  marginBottom: "0.5rem",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  padding: 0,

  "& .MuiAccordionSummary-content.Mui-expanded": {
    "& p": {
      color: "#00AE97",
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "1.25rem 2.5rem",
  borderTop: "none",
  borderRadius: "10px",
  border: "1px solid #464653",
  fontFamily: "Montserrat",
  fontWeight: 400,

  "@media (max-width:767.9px)": {
    padding: "1.25rem 1.5rem",
    fontSize: "0.875rem",
  },
}));
