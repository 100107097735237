import { Button } from "@mui/material";
import React from "react";

export const SolidButton = ({ onClick, children, my, mb, mt, type = "primary" }: any) => {
  const typeStyles =
    type === "primary"
      ? {
          backgroundColor: "#FF5349",
          color: "white",
          borderColor: "transparent",

          "&:hover": {
            backgroundColor: "#FF5349",
          },
        }
      : {
          backgroundColor: "transparent",
          color: "#150200",
          borderColor: "#150200",

          "&:hover": {
            backgroundColor: "transparent",
          },
        };

  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        fontSize: { md: "18px", xs: "14px" },
        fontFamily: "Poppins",
        fontWeight: 500,
        my: my,
        mb: mb,
        mt: mt,
        boxShadow: "none",
        borderRadius: "4px",
        textTransform: "capitalize",
        padding: { md: "1rem 2.5rem", xs: "0.75rem 1.9rem" },
        borderWidth: "1px",
        borderStyle: "solid",
        ...typeStyles,
      }}
    >
      {children}
    </Button>
  );
};
