import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import BookADemoPage from "./Components/BookADemo";
import React from "react";
import { Home, Download, Integration, PrivacyPolicy, TermsAndCondition, Faq, Pricing } from "./Pages";

function App() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:feature?" element={<Home />} />
      <Route path="/book-a-demo" element={<BookADemoPage />} />
      <Route path="/integration" element={<Integration />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/download-app" element={<Download />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
      <Route path="/faq" element={<Faq />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
