export const CartIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M20.6826 16.25C21.6201 16.25 22.4451 15.7375 22.8701 14.9625L27.3451 6.85C27.8076 6.025 27.2076 5 26.2576 5H7.75762L6.58262 2.5H2.49512V5H4.99512L9.49512 14.4875L7.80762 17.5375C6.89512 19.2125 8.09512 21.25 9.99512 21.25H24.9951V18.75H9.99512L11.3701 16.25H20.6826ZM8.94512 7.5H24.1326L20.6826 13.75H11.9076L8.94512 7.5ZM9.99512 22.5C8.62012 22.5 7.50762 23.625 7.50762 25C7.50762 26.375 8.62012 27.5 9.99512 27.5C11.3701 27.5 12.4951 26.375 12.4951 25C12.4951 23.625 11.3701 22.5 9.99512 22.5ZM22.4951 22.5C21.1201 22.5 20.0076 23.625 20.0076 25C20.0076 26.375 21.1201 27.5 22.4951 27.5C23.8701 27.5 24.9951 26.375 24.9951 25C24.9951 23.625 23.8701 22.5 22.4951 22.5Z"
        fill="#2C499A"
      />
    </svg>
  );
};

export const BankIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none">
      <path
        d="M17.5 7.78693V5.47159H12.5V7.78693H17.5ZM5 10.1023V22.8366H25V10.1023H5ZM25 7.78693C26.3875 7.78693 27.5 8.81726 27.5 10.1023V22.8366C27.5 24.1217 26.3875 25.152 25 25.152H5C3.6125 25.152 2.5 24.1217 2.5 22.8366L2.5125 10.1023C2.5125 8.81726 3.6125 7.78693 5 7.78693H10V5.47159C10 4.18658 11.1125 3.15625 12.5 3.15625H17.5C18.8875 3.15625 20 4.18658 20 5.47159V7.78693H25Z"
        fill="#039C88"
      />
    </svg>
  );
};

export const TravelIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M3.1502 23.3939H26.9002V25.8939H3.1502V23.3939ZM27.6127 11.6939C27.3502 10.6939 26.3127 10.0939 25.3127 10.3689L18.6752 12.1439L10.0502 4.10645L7.6377 4.74395L12.8127 13.7064L6.6002 15.3689L4.1377 13.4439L2.3252 13.9314L5.5627 19.5439L26.2752 14.0064C27.2877 13.7189 27.8752 12.6939 27.6127 11.6939Z"
        fill="#6702CD"
      />
    </svg>
  );
};

export const StarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M13.325 11.1125L14.4999 15H18.025L15.1874 17.025L16.3499 20.7875L13.325 18.4875L10.3 20.7875L11.4625 17.025L8.62495 15H12.15L13.325 11.1125ZM10.3 12.5H0.824951L8.53745 18.0125L5.61245 27.5L13.325 21.6375L21.0499 27.5L18.1124 18.0125L25.8249 12.5H16.3499L13.325 2.5L10.3 12.5ZM26.275 27.5L23.9499 19.9875L29.1749 16.25H24.8749L21.0249 19L22.85 24.9L26.275 27.5ZM20.8249 10L18.5499 2.5L17.25 6.8125L18.2125 10H20.8249Z"
        fill="#A78D03"
      />
    </svg>
  );
};

export const MeasureIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <g clip-path="url(#clip0_990_432)">
        <path
          d="M1.875 1.875V27.1875C1.875 27.4361 1.97377 27.6746 2.14959 27.8504C2.3254 28.0262 2.56386 28.125 2.8125 28.125H28.125"
          stroke="#6702CD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.14062 13.125H6.79688C6.14967 13.125 5.625 13.6497 5.625 14.2969V23.2031C5.625 23.8503 6.14967 24.375 6.79688 24.375H9.14062C9.78783 24.375 10.3125 23.8503 10.3125 23.2031V14.2969C10.3125 13.6497 9.78783 13.125 9.14062 13.125Z"
          stroke="#6702CD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5781 10.3125H15.2344C14.5872 10.3125 14.0625 10.8372 14.0625 11.4844V23.2031C14.0625 23.8503 14.5872 24.375 15.2344 24.375H17.5781C18.2253 24.375 18.75 23.8503 18.75 23.2031V11.4844C18.75 10.8372 18.2253 10.3125 17.5781 10.3125Z"
          stroke="#6702CD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.9945 6.5625H23.6508C23.0035 6.5625 22.4789 7.08717 22.4789 7.73438V23.2031C22.4789 23.8503 23.0035 24.375 23.6508 24.375H25.9945C26.6417 24.375 27.1664 23.8503 27.1664 23.2031V7.73438C27.1664 7.08717 26.6417 6.5625 25.9945 6.5625Z"
          stroke="#6702CD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_990_432">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GiftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M25 8.125H22.275C22.4125 7.7375 22.5 7.3125 22.5 6.875C22.5 4.8 20.825 3.125 18.75 3.125C17.4375 3.125 16.3 3.8 15.625 4.8125L15 5.65L14.375 4.8C13.7 3.8 12.5625 3.125 11.25 3.125C9.175 3.125 7.5 4.8 7.5 6.875C7.5 7.3125 7.5875 7.7375 7.725 8.125H5C3.6125 8.125 2.5125 9.2375 2.5125 10.625L2.5 24.375C2.5 25.7625 3.6125 26.875 5 26.875H25C26.3875 26.875 27.5 25.7625 27.5 24.375V10.625C27.5 9.2375 26.3875 8.125 25 8.125ZM18.75 5.625C19.4375 5.625 20 6.1875 20 6.875C20 7.5625 19.4375 8.125 18.75 8.125C18.0625 8.125 17.5 7.5625 17.5 6.875C17.5 6.1875 18.0625 5.625 18.75 5.625ZM11.25 5.625C11.9375 5.625 12.5 6.1875 12.5 6.875C12.5 7.5625 11.9375 8.125 11.25 8.125C10.5625 8.125 10 7.5625 10 6.875C10 6.1875 10.5625 5.625 11.25 5.625ZM25 24.375H5V21.875H25V24.375ZM25 18.125H5V10.625H11.35L8.75 14.1625L10.775 15.625L15 9.875L19.225 15.625L21.25 14.1625L18.65 10.625H25V18.125Z"
        fill="#F02005"
      />
    </svg>
  );
};

export const RetainIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M5 20.625C6.38071 20.625 7.5 19.5057 7.5 18.125C7.5 16.7443 6.38071 15.625 5 15.625C3.61929 15.625 2.5 16.7443 2.5 18.125C2.5 19.5057 3.61929 20.625 5 20.625Z"
        fill="#A78D03"
      />
      <path
        d="M1.525 22.6C0.6 23 0 23.9 0 24.9125V26.875H5.625V24.8625C5.625 23.825 5.9125 22.85 6.4125 22C5.95 21.925 5.4875 21.875 5 21.875C3.7625 21.875 2.5875 22.1375 1.525 22.6Z"
        fill="#A78D03"
      />
      <path
        d="M25 20.625C26.3807 20.625 27.5 19.5057 27.5 18.125C27.5 16.7443 26.3807 15.625 25 15.625C23.6193 15.625 22.5 16.7443 22.5 18.125C22.5 19.5057 23.6193 20.625 25 20.625Z"
        fill="#A78D03"
      />
      <path
        d="M28.475 22.6C27.4125 22.1375 26.2375 21.875 25 21.875C24.5125 21.875 24.05 21.925 23.5875 22C24.0875 22.85 24.375 23.825 24.375 24.8625V26.875H30V24.9125C30 23.9 29.4 23 28.475 22.6Z"
        fill="#A78D03"
      />
      <path
        d="M20.3 21.4375C18.8375 20.7875 17.0375 20.3125 15 20.3125C12.9625 20.3125 11.1625 20.8 9.7 21.4375C8.35 22.0375 7.5 23.3875 7.5 24.8625V26.875H22.5V24.8625C22.5 23.3875 21.65 22.0375 20.3 21.4375ZM10.0875 24.375C10.2 24.0875 10.425 23.85 10.7 23.725C12.075 23.1125 13.525 22.8125 14.9875 22.8125C16.4625 22.8125 17.9 23.125 19.275 23.725C19.5625 23.85 19.775 24.0875 19.8875 24.375H10.0875Z"
        fill="#A78D03"
      />
      <path
        d="M11.25 15.625C11.25 17.7 12.925 19.375 15 19.375C17.075 19.375 18.75 17.7 18.75 15.625C18.75 13.55 17.075 11.875 15 11.875C12.925 11.875 11.25 13.55 11.25 15.625ZM16.25 15.625C16.25 16.3125 15.6875 16.875 15 16.875C14.3125 16.875 13.75 16.3125 13.75 15.625C13.75 14.9375 14.3125 14.375 15 14.375C15.6875 14.375 16.25 14.9375 16.25 15.625Z"
        fill="#A78D03"
      />
      <path
        d="M3.1 14.2C2.7125 13.25 2.5 12.325 2.5 11.375C2.5 8.15 5.025 5.625 8.25 5.625C11.6 5.625 13.025 7.8 15 10.1125C16.9625 7.825 18.375 5.625 21.75 5.625C24.975 5.625 27.5 8.15 27.5 11.375C27.5 12.325 27.2875 13.25 26.9 14.2C27.7125 14.5875 28.375 15.225 28.8125 16C29.5625 14.5 30 12.975 30 11.375C30 6.75 26.375 3.125 21.75 3.125C19.1375 3.125 16.6375 4.3375 15 6.2625C13.3625 4.3375 10.8625 3.125 8.25 3.125C3.625 3.125 0 6.75 0 11.375C0 12.975 0.4375 14.5 1.2 16C1.6375 15.225 2.3 14.5875 3.1 14.2Z"
        fill="#A78D03"
      />
    </svg>
  );
};
