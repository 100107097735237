import { WhyPaycurraBox } from "./whyPaycurra.styles";
import WhyPaycurraMockImg from "../../../../Assets/images/3-mobile-screen-mock.png";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { LoyaltyProgram } from "../loyaltyProgram";

export const WhyPaycurra = () => {
  return (
    <WhyPaycurraBox>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-center align-items-center order-2 order-lg-1">
            <img src={WhyPaycurraMockImg} className="img-fluid" alt="why paycurra" />
          </div>
          <div className="col-lg-6 order-1 order-lg-2 mt-lg-0 pt-lg-0">
            <div className="textHolder">
              <h3 className="title">
                A secure and rewarding <br className="d-none d-lg-block" /> way to shop
              </h3>
            </div>

            <ul className="reasonContainer">
              <li className="reasonHolder">
                <h5 className="title">Earn, Save, Repeat</h5>
                <p className="details">
                  Shop regularly and earn exclusive cashback, loyalty points, and coupons which can be tracked in real-time through our app. Keep tabs
                  on your earnings and points balance from a variety of merchants all in one place.
                </p>
                {/* <Link to="/">
                  Learn more
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow_right_alt" clipPath="url(#clip0_793_703)">
                      <path id="Vector" d="M16.01 11H4V13H16.01V16L20 12L16.01 8V11V11Z" fill="#FF5349" />
                    </g>
                    <defs>
                      <clipPath id="clip0_793_703">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link> */}
              </li>

              <li className="reasonHolder">
                <h5 className="title">Delightful payment options</h5>
                <p className="details">
                  Complete your checkout process using your preferred payment option. Choose to checkout with card, loyalty points, coupon rewards,
                  and gift cards from your favourite brands.
                </p>
                {/* <Link to="/">
                  Learn more
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow_right_alt" clipPath="url(#clip0_793_703)">
                      <path id="Vector" d="M16.01 11H4V13H16.01V16L20 12L16.01 8V11V11Z" fill="#FF5349" />
                    </g>
                    <defs>
                      <clipPath id="clip0_793_703">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link> */}
              </li>

              <li className="reasonHolder">
                <h5 className="title">Savings from your favourite brands</h5>
                <p className="details">
                  Discover the greatest offers from your favourite brands, all within the app. Reserve coupons or add loyalty cards to your mobile
                  wallet for added convenience.
                </p>
                {/* <Link to="/">
                  Learn more
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow_right_alt" clipPath="url(#clip0_793_703)">
                      <path id="Vector" d="M16.01 11H4V13H16.01V16L20 12L16.01 8V11V11Z" fill="#FF5349" />
                    </g>
                    <defs>
                      <clipPath id="clip0_793_703">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link> */}
              </li>
            </ul>
          </div>
        </div>

        <LoyaltyProgram />
      </div>
    </WhyPaycurraBox>
  );
};
