import { styled } from "@mui/material";

export const DownloadBox = styled("section")(({ theme }) => ({
  background: "#232323",

  "& .textHolder": {
    color: "#ffffff",
    padding: "7rem 0",

    "& .title": {
      fontSize: "2rem",
      fontWeight: 500,
      fontFamily: "Poppins",
      marginBottom: "3.5rem",
      lineHeight: 1.45,
    },

    "& .details": {
      fontSize: "1.15rem",
      fontWeight: 400,
      fontFamily: "Raleway",
      margin: "1.25rem 0 2.25rem",
    },
  },

  "& .imgContainer": {
    position: "relative",
    // overflow: "hidden",

    "& .img1, .img2": {
      position: "absolute",
      // top: "-7rem",
      //   bottom: 0,
    },
    "& .img1": {
      zIndex: 2,
      top: "-8.55rem",
    },
    "& .img2": {
      zIndex: 1,
      top: "-11.1rem",
      right: "-29%",
    },
  },

  "& .downloadBtnContainer": {
    "& img": {
      cursor: "pointer",
    },
  },

  "@media (max-width:1399.9px)": {
    "& .textHolder": {
      padding: "6.5rem 0",

      "& .title": {
        fontSize: "1.85rem",
        marginBottom: "3.5rem !important",
      },
    },

    "& .imgContainer": {
      "& .img1": {
        zIndex: 2,
        top: "-2.1rem",
      },
      "& .img2": {
        zIndex: 1,
        top: "-6.55rem",
        right: "-29%",
      },
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:1199.9px)": {
    "& .textHolder": {
      padding: "6rem 0",

      "& .title": {
        fontSize: "1.75rem",
        marginBottom: "1rem !important",
      },

      "& .details": {
        fontSize: "1rem",
        margin: "1.25rem 0 2.25rem",
      },
    },

    "& .imgContainer": {
      position: "relative",
      overflow: "hidden",

      "& .img1, .img2": {
        position: "absolute",

        //   bottom: 0,
      },
      "& .img1": {
        zIndex: 2,
        top: "-2.1rem",
      },
      "& .img2": {
        zIndex: 1,
        top: "-7.5rem",
        right: "-29%",
      },
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:767.9px)": {
    "& .textHolder": {
      padding: "5rem 0",
      textAlign: "center",

      "& .title": {
        fontSize: "1.5rem",
        lineHeight: 1.5,
        marginBottom: '1.5rem !important'
      },

      "& .downloadBtnContainer": {
        justifyContent: "center",
      },

      "& .details": {
        fontSize: "0.95rem",
        margin: "1.25rem 0 2.25rem",
      },
    },
  },

  "@media (max-width:519.9px)": {
    "& .downloadBtnContainer": {
      "& img": {
        maxWidth: "150px",
      },
    },
  },
}));
