import { styled } from "@mui/material";

export const HeaderBox = styled("header")(({ theme }) => ({
  background: "radial-gradient(50% 50.00% at 50% 50.00%, rgba(248, 188, 185, 0.20) 0%, rgba(244, 50, 73, 0.00) 100%)",
  padding: "0 0 5rem",
  position: "relative",
  minHeight: "900px",
  overflow: "hidden",

  "& .textHolder": {
    margin: "8rem 3.5rem 0 0",

    "& .title": {
      color: "#150200",
      fontSize: "3.125rem",
      fontWeight: 600,
      lineHeight: "1.4",

      "& span": {
        fontFamily: "Merienda",
        color: "#FF5349",
        position: "relative",
        "& svg": {
          position: "absolute",
          right: 0,
          top: 0,
        },
      },
    },

    "& .details": {
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "1.15rem",
      color: "#454242",
      lineHeight: "1.5",
      marginRight: "2rem",
    },
  },

  "& .imageHolder": {
    position: "relative",
    marginTop: "4rem",

    "& .checkoutMock": {
      position: "absolute",
      top: "-3rem",
      right: "-60%",
      zIndex: 1,

      "& img": {
        maxWidth: "800px",
      },
    },

    "& .mobile": {
      zIndex: 2,
      left: "-5rem",
      top: "-1rem",
      position: "absolute",
      maxWidth: "570px",
    },
  },

  "& .btnContainer": {
    display: "flex",
    gap: " 0 1rem",
    alignItems: "center",
    flexWrap: "wrap",

    "& .btnOutline": {
      textTransform: "capitalize !important",
      border: "1px solid #150200",
      borderRadius: "4px",
      background: "transparent",
      fontWeight: 500,
      fontSize: "1.05rem",
      padding: "1rem 2.5rem",
      color: "#150200",
      transition: ".3s all ease-in-out",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },
  },

  "@media (max-width:1200px)": {
    minHeight: "800px",

    "& .textHolder": {
      margin: "5rem 0 3rem",

      "& .title": {
        fontSize: "2.75rem",
      },

      "& .details": {
        fontSize: "1.05rem",
        margin: "1rem 0rem 2.5rem",
      },

      "& button": {
        margin: "0",
      },
    },

    "& .imageHolder": {
      marginTop: "4rem",

      "& .checkoutMock": {
        position: "absolute",
        top: "-3rem",
        right: "-9rem",
        zIndex: 1,

        "& img": {
          maxWidth: "500px",
        },
      },

      "& .mobile": {
        zIndex: 2,
        left: "0",
        top: "-1rem",
        maxWidth: "300px",
      },
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:991.9px)": {
    minHeight: "1000px",
    padding: "0 0 2rem",

    "& .textHolder": {
      margin: "0 0 3rem",

      "& .title": {
        fontSize: "2.75rem",
      },

      "& .details": {
        fontSize: "1.05rem",
        margin: "1rem 0rem 2.5rem",
      },

      "& .btnContainer": {
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
      },

      "& button": {
        margin: "0",
      },
    },

    "& .imageHolder": {
      marginTop: "4rem",

      "& .checkoutMock": {
        position: "absolute",
        top: "-3rem",
        right: 0,
        zIndex: 1,

        "& img": {
          // maxWidth: "400px",
        },
      },

      "& .mobile": {
        zIndex: 2,
        left: "3rem",
        top: "-1rem",
        position: "absolute",
        maxWidth: "350px",
      },
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:767.9px)": {
    "& .textHolder": {
      marginTop: "2.5rem",
      textAlign: "center",

      "& .title": {
        fontSize: "2.25rem",
      },

      "& .details": {
        fontSize: "1rem",
      },

      "& .btnContainer": {
        justifyContent: "center",
        gap: "1.5rem",
      },
    },

    "& .imageHolder": {
      marginTop: "4rem",

      "& .checkoutMock": {
        position: "absolute",
        top: "-3rem",
        right: "-2rem",
        zIndex: 1,

        "& img": {
          maxWidth: "400px",
        },
      },

      "& .mobile": {
        zIndex: 2,
        left: "0",
        top: "-1rem",
        position: "absolute",
        maxWidth: "300px",
      },
    },
  },

  "@media (max-width:519.9px)": {
    "& .imageHolder": {
      "& .checkoutMock": {
        top: "-3rem",
        right: "-6rem",
        zIndex: 1,

        "& img": {
          maxWidth: "380px",
        },
      },

      "& .mobile": {
        zIndex: 2,
        left: "-2rem",
        top: "-1rem",
        maxWidth: "280px",
      },
    },
  },
}));
