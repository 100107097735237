import Layout from "../../Components/Layouts";
import React from "react";
import Navbar from "../../Components/Header";
import styles from "./styles.module.css";
import DownloadImg1 from "../../Assets/images/download-img-1.svg";
import DownloadImg2 from "../../Assets/images/download-img-2.svg";
import AppStoreImg from "../../Assets/images/download-app-store.svg";
import PlayStoreImg from "../../Assets/images/download-play-store.svg";
import { LoyaltyProgram } from "../Home/components";
import { RetainIcon } from "../../Components/Home/Components/icons";
import BuyPointImg from "../../Assets/icons/buy-point.svg";
import GiftCardPinkImg from "../../Assets/icons/gift-card-pink.svg";
import { Box } from "@mui/material";
import { Seo } from "../../Components/Seo";
import DownloadPaycurra from "../../Assets/images/download-paycurra.png";

export const Download = () => {
  return (
    <>
      <Layout>
        {/* <AppHead title={"Welcome"} children={undefined} /> */}
        <Seo
          title="Download Paycurra To Enjoy Exclusive loyalty deals"
          description="Download Paycurra on AppStore and Google play store To Enjoy Exclusive loyalty deals"
          keywords="paycurra, download, reward, customer, loyalty, campaign, bonus, gift, discount, boost, sales, attract, retain, build, brand customer, engage customer"
          image={DownloadPaycurra}
        />

        <header className={styles.headerSection}>
          <Navbar />

          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className={`${styles.headerText} my-5 mt-lg-0 me-lg-5 pb-0 pb-lg-5`}>
                  <h3 className={`${styles.title} mb-3`}>To begin, download the Paycurra app on your phone.</h3>
                  <p className={`${styles.details} mb-4`}>
                    To complete your Paycurra app registration, you may be required to provide documented proof of your identity.
                    {/* Download Paycurra today to enjoy amazing discounts, gift cards, buying points, and thrilling cashbacks from top brands. */}
                  </p>
                  <div className={`d-flex align-items-center gap-3 ${styles.appBtnContainer}`}>
                    <img src={AppStoreImg} className={`img-fluid`} alt="Download paycurra on App store" />
                    <img src={PlayStoreImg} className={`img-fluid`} alt="Download paycurra on Play store" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end">
                <div className={`${styles.imgHolder}`}>
                  <img src={DownloadImg2} className={` ${styles.img2}`} alt="Checkout merchant" />
                  <img src={DownloadImg1} className={` ${styles.img1}`} alt="Checkout merchant" />
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className={styles.loyaltySection}>
          <div className="container">
            <div className="col-xl-8 col-md-10 my-5">
              <div className={styles.textHolder}>
                <h4 className={`${styles.title} mb-3`}> Rewarding ways to shop and pay</h4>
                <p className={`${styles.details} mb-3`}>
                  Enjoy discounts, cashbacks, free gifts and loyalty points from your preferred brands and stores.
                </p>
              </div>
            </div>

            <div className="row pt-5">
              <div className={`col-md-4 ${styles.offerList}`}>
                <Box sx={{ background: "rgba(186, 169, 203, 0.20)" }} className="p-2 d-inline-flex mb-3 rounded">
                  <img src={BuyPointImg} />
                </Box>
                <h5 className={styles.title}>More ways to pay</h5>
                <p className={styles.details}>
                  Reserve deals to your wallet for usage when you checkout with Paycurra from merchant stores. Add rechargeable payment card tokens
                  and review checkout transactions.
                </p>
              </div>

              <div className={`col-md-4 ${styles.offerList}`}>
                <Box sx={{ background: "rgba(224, 54, 163, 0.20)" }} className="p-2 d-inline-flex mb-3 rounded">
                  <img src={GiftCardPinkImg} />
                </Box>
                <h5 className={styles.title}>Gift cards</h5>
                <p className={styles.details}>
                  Surprise your loved ones with gift cards on their special occasions. Checkout using a gift card and keep track of the remaining
                  balance.
                </p>
              </div>

              <div className={`col-md-4 ${styles.offerList}`}>
                <Box sx={{ background: "#FFF7E9" }} className="p-2 d-inline-flex mb-3 rounded">
                  <RetainIcon />
                </Box>
                <h5 className={styles.title}>Stay loyal to brands you love</h5>
                <p className={styles.details}>
                  Join merchant loyalty programmes to gain access to exclusive deals. Keep tabs on your point earnings, spending, and transfers.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
