import React from "react";
import styles from "./footer.module.css";

import { Link, useNavigate } from "react-router-dom";
import { Box, Container, Stack, Typography } from "@mui/material";
import paycurra from "../../Assets/logo/logo-paycurra.png";
import { FooterLinkHeader, FooterSocialHeader } from "../Typography";
import { FaInstagram, FaTwitter, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FooterBox } from "./footer.styles";

const Footer = () => {
  const navigate = useNavigate();

  const handleRoute = (link: string, name: string) => {
    navigate(link, { state: { goTo: "feature" } });
  };

  return (
    <FooterBox>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-5 col-12">
            <div className="d-inline-flex flex-column logo">
              <img src={paycurra} alt="" className="img-fluid" />
              <small className="">
                Commerce platform by <a href="https://www.cognitivelabs.co" target="_blank">Cognitive Labs</a>
              </small>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <ul className="footerList">
              <li className="title">Products</li>
              <li className="item">
                <a href="https://merchants.paycurra.com" target="_blank">
                  Paycurra Merchant
                </a>
              </li>
              <li className="item">
                <a href="https://checkout.paycurra.com" target="_blank">
                  Paycurra Checkout
                </a>
              </li>
              <li className="item">
                <Link to={"/download-app"}>Paycurra Mobile</Link>
              </li>
            </ul>

            <div className="socialContainer">
              <h5 className="title">Connect with us</h5>
              <div className="iconHolder">
                <a href="https://www.instagram.com/paycurra" target="_blank">
                  <FaInstagram />
                </a>
                <a href="https://www.facebook.com/paycurra" target="_blank">
                  <FaFacebookF />
                </a>
                {/* <a href="#" target="_blank">
                  <FaLinkedinIn />
                </a> */}
                <a href="https://www.twitter.com/paycurra" target="_blank">
                  <FaTwitter />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-6">
            <ul className="footerList">
              <li className="title">Company</li>
              <li className="item" onClick={() => handleRoute("featureSection", "Features")}>
                Features
              </li>
              {/* <li className="item">Pricing</li> */}
              {/* <li className="item">Insights</li> */}
              <li className="item">
                <Link to={"/integration"}>Integration </Link>
              </li>
              {/* <li className="item">Paycurra</li> */}
            </ul>
          </div>
        </div>

        <div className="legalSection">
          <p className="mb-0 copyText">&copy; {new Date().getFullYear()} All rights reserved.</p>

          <div className="legalLinks">
            <Link to={"/privacy-policy"} className="">
              Privacy policy
            </Link>
            <Link to={"/terms-and-conditions"} className="">
              Terms and conditions
            </Link>
            <Link to={"/faq"} className="">
              FAQ
            </Link>
          </div>
        </div>
      </div>
      {/* <Container maxWidth="xl">
        <Box
          sx={{
            width: "100%",
            padding: { md: "78px", xs: "32px" },
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              width: { md: "200px", xs: "100px" },
              marginBottom: "15px",
            }}
          >
            <img src={paycurra} alt="" width="100%" />
          </Box>
          <Stack direction={{ xs: "row", md: "row" }} spacing={8}>
            <div className={`${styles.column1}`}>
              <FooterLinkHeader>Products</FooterLinkHeader>
              <Link to={`#`} className={`${styles.footerLinkItem}`}>
                Paycurra checkout
              </Link>
              <Link to={`#`} className={`${styles.footerLinkItem}`}>
                Paycurra merchant
              </Link>
              <div style={{ marginTop: "25px" }}>
                <FooterSocialHeader>Connect with us</FooterSocialHeader>

                <div className={`${styles.socialIcon}`}>
                  <Link to="#">
                    <FaInstagram />
                  </Link>
                  <Link to="#">
                    <FaFacebookF />
                  </Link>
                  <Link to="#">
                    <FaLinkedinIn />
                  </Link>
                  <Link to="#">
                    <FaTwitter />
                  </Link>
                </div>
              </div>
            </div>
            <div className={`${styles.column1}`}>
              <FooterLinkHeader>Company</FooterLinkHeader>
              <Link to={`#`} className={`${styles.footerLinkItem}`}>
                Features
              </Link>
              <Link to={`#`} className={`${styles.footerLinkItem}`}>
                Pricing
              </Link>
              <Link to={`#`} className={`${styles.footerLinkItem}`}>
                Insights
              </Link>
              <Link to={`#`} className={`${styles.footerLinkItem}`}>
                Integration
              </Link>
              <Link to={`#`} className={`${styles.footerLinkItem}`}>
                Paycurra
              </Link>
            </div>
          </Stack>
        </Box>
      </Container> */}
    </FooterBox>
  );
};

export default Footer;
