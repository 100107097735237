export const CircleListIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <circle cx="14" cy="14" r="12" fill="#FFD6D0" />
      <circle cx="11.5" cy="11.5" r="11.5" fill="#FAFAFA" />
      <path
        d="M12.25 0.25C9.87663 0.25 7.55655 0.953788 5.58316 2.27236C3.60977 3.59094 2.0717 5.46508 1.16345 7.6578C0.255199 9.85051 0.0175594 12.2633 0.480582 14.5911C0.943605 16.9189 2.08649 19.057 3.76472 20.7353C5.44295 22.4135 7.58115 23.5564 9.90892 24.0194C12.2367 24.4824 14.6495 24.2448 16.8422 23.3365C19.0349 22.4283 20.9091 20.8902 22.2276 18.9168C23.5462 16.9434 24.25 14.6234 24.25 12.25C24.2466 9.06843 22.9813 6.01814 20.7316 3.76843C18.4819 1.51872 15.4316 0.25336 12.25 0.25ZM12.25 22.4038C10.2418 22.4038 8.27862 21.8083 6.60883 20.6926C4.93904 19.5769 3.63759 17.9911 2.86907 16.1357C2.10055 14.2803 1.89947 12.2387 2.29126 10.2691C2.68305 8.29943 3.65011 6.49019 5.07015 5.07015C6.49019 3.65011 8.29943 2.68305 10.2691 2.29126C12.2387 1.89947 14.2803 2.10055 16.1357 2.86907C17.9911 3.63759 19.5769 4.93903 20.6926 6.60882C21.8083 8.27861 22.4038 10.2418 22.4038 12.25C22.4008 14.942 21.33 17.5229 19.4265 19.4265C17.5229 21.33 14.942 22.4008 12.25 22.4038Z"
        fill="#150200"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6.75806 17.2428L12.0011 11.9998L17.2441 17.2428M17.2441 6.75684L12.0001 11.9998L6.75806 6.75684"
        stroke="#847F7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GreenCheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M20 3.74951C11.0398 3.74951 3.75 11.0394 3.75 19.9995C3.75 28.9597 11.0398 36.2495 20 36.2495C28.9602 36.2495 36.25 28.9597 36.25 19.9995C36.25 11.0394 28.9602 3.74951 20 3.74951ZM28.457 14.5534L17.957 27.0534C17.8419 27.1906 17.6985 27.3014 17.5368 27.3784C17.375 27.4554 17.1986 27.4967 17.0195 27.4995H16.9984C16.8232 27.4994 16.65 27.4626 16.49 27.3912C16.3299 27.3199 16.1867 27.2157 16.0695 27.0854L11.5695 22.0854C11.4552 21.9642 11.3663 21.8214 11.3081 21.6653C11.2498 21.5093 11.2232 21.3431 11.2301 21.1767C11.2369 21.0102 11.277 20.8468 11.3478 20.6961C11.4187 20.5453 11.519 20.4102 11.6429 20.2988C11.7667 20.1873 11.9116 20.1018 12.0689 20.0471C12.2263 19.9925 12.393 19.9698 12.5593 19.9805C12.7255 19.9912 12.888 20.035 13.037 20.1094C13.1861 20.1838 13.3188 20.2872 13.4273 20.4136L16.9656 24.3448L26.543 12.9456C26.7578 12.6972 27.0617 12.5434 27.3891 12.5173C27.7165 12.4912 28.0409 12.595 28.2924 12.8062C28.5438 13.0174 28.7021 13.3191 28.7329 13.6461C28.7637 13.973 28.6646 14.2989 28.457 14.5534Z"
        fill="#00AE97"
      />
    </svg>
  );
};
