import Layout from "../../Components/Layouts";
import Navbar from "../../Components/Header";
import styles from "./styles.module.css";
import { GreenCheckIcon } from "../../Assets/svgs";
import CrownGoldIcon from "../../Assets/icons/crown-gold-icon.svg";
import InnovationGreenIcon from "../../Assets/icons/innovation-green-icon.svg";
import MagentaIcon from "../../Assets/icons/magneta-icon.svg";
import WalletPinkIcon from "../../Assets/icons/wallet-pink-icon.svg";

export const Pricing = () => {
  return (
    <Layout>
      <header className={styles.header_section}>
        <Navbar />

        <div className="container">
          <div className={styles.text_holder}>
            <h5 className={styles.sub_title}>Pricing</h5>
            <h2 className={styles.title}>Clear and simple pricing for businesses big and small</h2>
            <p className={styles.details}>Pay-as-you-go, zero setup or hidden fees.</p>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-6">
              <div className={styles.pricing_card}>
                <h5 className={styles.title}>Custom package</h5>
                <div className={styles.details}>
                  <GreenCheckIcon />
                  <span>Get volume redemption, multi-merchant account and API call rate rebates.</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-6">
              <div className={styles.pricing_card}>
                <h5 className={styles.title}>Standard</h5>
                <div className={styles.details}>
                  <GreenCheckIcon />
                  <span>Circa 1.5% per value redemption; flat loyalty issuance fee; 500 free monthly API calls</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className={styles.what_you_get_section}>
        <div className="container">
          <div className={styles.text_holder}>
            <h3 className={styles.title}>What you get</h3>
            <p className={styles.details}>Get a bundle of out-of-the box features to get you started on your growth journey.</p>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className={styles.benefit_card}>
                <div className={`${styles.icon_holder} ${styles.icon_1}`}>
                  <img src={MagentaIcon} className="img-fluid" />
                </div>
                <div className={styles.title}>Tools to delight your customers with more ways to shop and pay at checkout</div>
                <ul className={styles.list}>
                  <li>
                    <GreenCheckIcon />
                    <span>Bring your own payment gateway</span>
                  </li>
                  <li>
                    <GreenCheckIcon />
                    <span>Pay with points, coupons, cashbacks and cards</span>
                  </li>
                  <li>
                    <GreenCheckIcon />
                    <span>Preview of checkout earnings to incentivise conversion and grow cart value</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <div className={styles.benefit_card}>
                <div className={`${styles.icon_holder} ${styles.icon_2}`}>
                  <img src={WalletPinkIcon} className="img-fluid" />
                </div>
                <div className={styles.title}>Tools to create and manage a winning loyalty programmes</div>
                <ul className={styles.list}>
                  <li>
                    <GreenCheckIcon />
                    <span>Coalition programmes</span>
                  </li>
                  <li>
                    <GreenCheckIcon />
                    <span>Multi-tiered and paid membership programmes</span>
                  </li>
                  <li>
                    <GreenCheckIcon />
                    <span>Publishing of loyalty membership programmes to the Paycurra mobile wallet app</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <div className={styles.benefit_card}>
                <div className={`${styles.icon_holder} ${styles.icon_3}`}>
                  <img src={CrownGoldIcon} className="img-fluid" />
                </div>
                <div className={styles.title}>Tools to create and plan effective promotional campaigns</div>
                <ul className={styles.list}>
                  <li>
                    <GreenCheckIcon />
                    <span>Flexible rules engine</span>
                  </li>
                  <li>
                    <GreenCheckIcon />
                    <span>Multiple coupon types: discount, stamps, free gifts, cash back</span>
                  </li>
                  <li>
                    <GreenCheckIcon />
                    <span>Usage tracking and monitoring</span>
                  </li>
                  <li>
                    <GreenCheckIcon />
                    <span>Publishing of promotion-linked rewards to the Paycurra mobile wallet app</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <div className={styles.benefit_card}>
                <div className={`${styles.icon_holder} ${styles.icon_4}`}>
                  <img src={InnovationGreenIcon} className="img-fluid" />
                </div>
                <div className={styles.title}>Tools to gain insight into customer behaviour and manage engagements</div>
                <ul className={styles.list}>
                  <li>
                    <GreenCheckIcon />
                    <span>Measure and track customer engagement KPIs</span>
                  </li>
                  <li>
                    <GreenCheckIcon />
                    <span>Review consumer checkout sessions and manage disputes</span>
                  </li>
                  <li>
                    <GreenCheckIcon />
                    <span>Personalise rewards based on customer insights</span>
                  </li>
                  <li>
                    <GreenCheckIcon />
                    <span>Recover abandoned carts with personalised emails and linked coupon offerings tailored to convert</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};
