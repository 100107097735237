import { Helmet } from "react-helmet";
import * as React from "react"

interface IProps{
    title: string;
    description: string;
    image: string;
    keywords: string;
}

export const Seo: React.FC<IProps> = ({
    title,
    description,
    keywords,
    image
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* <meta name="author" content="Your Name" /> */}
      <link rel="canonical" href={window.location.href} />

      {/* Social media -opengraph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};
